@use 'common';

.sl-body {
	.mat-mdc-card {
		--mdc-outlined-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
		--mat-card-title-text-line-height: inherit;
		--mat-card-title-text-tracking: normal;
		border: none;
		padding: common.space(2);
	}

	.mat-mdc-card-header {
		padding: 0;
	}

	.mat-mdc-card-content {
		font-size: 14px;
		padding: common.space(2) 0 0 0;

		&:last-child {
			padding-bottom: 0;
		}
	}

	.mdc-card__actions {
		min-height: auto;
		padding: 0;
	}
}
