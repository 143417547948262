@use 'common' as *;

.sl-body {
	.cdk-overlay-pane {
		max-width: 90vw;
		max-height: 90vh;

		&.new-design {
			.mat-mdc-dialog-container {
				--mdc-dialog-subhead-tracking: normal;
				--mdc-dialog-supporting-text-tracking: normal;

				.mat-mdc-dialog-surface {
					@include sl-fancy-scroll;
					padding: space(3);

					.mat-mdc-dialog-title {
						font-size: 20px;
						margin-bottom: space(2);
						padding: 0;

						&::before {
							display: none;
						}
					}

					.mat-mdc-dialog-content {
						@include color(inherit);
						font-size: 14px;
						font-weight: 400;
						line-height: normal;
						margin: 0;
						padding: 0;
						max-height: 100%;
						flex: 1 1 100%;
					}

					.mat-mdc-dialog-actions {
						gap: space(2);
						margin: space(3) 0 0 0;
						min-height: auto;
						padding: 0;

						.mat-mdc-button {
							font-size: 14px;
							font-weight: 500;

							&:hover {
								/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
								.mat-button-focus-overlay {
									opacity: 0;
								}
							}

							&.primary-button {
								text-decoration: none;

								&:not(:disabled) {
									@include color(var(--sl-white-main));
									background-color: var(--sl-primary-brand-active);

									&:hover {
										background-color: var(--sl-primary-brand-hover);
									}
								}
							}
						}
					}
				}
			}

		}

		&.dashboard-quality-gate-build-dialog {
			@include box-shadow(0, 0, 10px, 5px, rgba(0, 0, 0, 0.1));
			border-radius: 10px;
			width: 700px;
			height: auto;
			place-content: stretch stretch;

			.mat-mdc-dialog-container {
				--mdc-dialog-supporting-text-tracking: normal;
				padding-bottom: 0;
			}
		}
	}
}
