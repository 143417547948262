@use 'common' as *;
@use '../table' as *;
@use '../../../overrides/dynamic-table/index.scss';

$border-color: var(--sl-dark-background-main);

.sl-v1-table-primeng,
.sl-v1-treetable-primeng {
	p-tablecheckbox {
		display: flex;
		margin-top: -4px;
	}

	sl-search-box {
		.sl-input-container {
			min-width: unset;
		}
	}

	&.noData {
		.p-datatable-table {
			height: 100%;
		}
	}

	.p-datatable-wrapper,
	.p-treetable-wrapper {
		@include sl-fancy-scroll;
		border: 1px solid $border-color;
		background-color: var(--sl-white-main);
	}

	.p-treetable-scrollable-body {
		@include sl-fancy-scroll;
	}

	.p-resizable-column:hover {
		.p-column-resizer {
			opacity: 1;
		}
	}

	.p-column-resizer {
		border-left: 2px dotted var(--sl-disabled-mode-lines-400);
		margin-top: 5px;
		height: 24px;
		opacity: 0;
	}

	.p-datatable-thead,
	.p-treetable-thead {
		z-index: 2;

		tr {
			height: 40px !important;
			background-color: var(--sl-dark-background-200);

			th {
				@include table-header-cell;
				background-color: var(--sl-dark-background-200);

				&.th_checkbox {
					width: 38px !important;
				}

				&.th_expand {
					width: 42px !important;
					max-width: 42px;
				}
			}
		}
	}

	tr {
		height: 48px !important;

		td,
		th {
			border: none;
			border-bottom: 1px solid $border-color;
			padding: 0 12px;

			&.p-frozen-column:not(.actions) {
				background: var(--sl-background-main);
			}

			&.td_expand {
				cursor: pointer;
				max-width: 42px;
			}

			&.no-border {
				border: none;
			}
		}

		td {
			&.actions * {
				opacity: 0;
			}
		}

		&:hover {
			background-color: var(--sl-dark-background-100) !important;

			td {
				&.actions * {
					opacity: 1;
				}
			}
		}
	}

	.p-datatable-header,
	.p-treetable-header {
		border: 1px solid var(--sl-dark-background-main);
		border-bottom: 0;
		background: var(--sl-white-main);
		border-radius: 8px 8px 0 0;
		padding: 8px 24px;
		font-weight: unset;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.p-datatable,
	.p-treetable {

		.p-datatable-header,
		.p-treetable-header {
			border: 1px solid var(--sl-dark-background-main);
			border-bottom: 0;
			background: var(--sl-white-main);
			border-radius: 8px 8px 0 0;
		}

		.p-sortable-column {
			&:hover {
				.p-sortable-column-icon {
					visibility: visible;
				}
			}

			.p-sortable-column-icon {
				height: 14px;
				color: var(--sl-secondary-text-icon-300);
				visibility: hidden;
			}

			&.p-highlight .p-sortable-column-icon {
				color: var(--sl-secondary-text-icon-300);
				visibility: visible;
			}
		}

		&.p-datatable-hoverable-rows {
			.p-datatable-tbody>tr:not(.p-highlight):hover {
				background: var(--sl-white-main);
			}
		}
	}

	.p-datatable-footer,
	.p-treetable-footer {
		@include table-footer;
		border-radius: 0px 0px 8px 8px;
	}

	.p-paginator {
		background: none;
		border: none;

		.p-dropdown-items {
			padding: 8px 0;
		}

		.p-dropdown-item {
			padding: 4px 10px;
		}

		.p-paginator-element,
		.p-dropdown {
			height: 32px;
			border-radius: 4px;
			font-size: 12px;
		}

		.p-dropdown .p-inputtext {
			padding-top: 8px;
			font-size: 12px;
			align-self: center;
		}

		.p-paginator-page.p-highlight {
			background: var(--sl-primary-brand-active);
			@include color(var(--sl-white-main));
		}

		&.sl-paginator {
			@include table-footer;
			border-radius: 0px 0px 8px 8px;
			background: var(--sl-white-main);
			padding: 0.5rem 1rem;
		}
	}

	.p-datatable-loading,
	.p-treetable-loading {
		.p-component-overlay {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}

	.p-datatable-loading-overlay {
		background: var(--sl-disabled-mode-lines-500);
		mix-blend-mode: multiply;
	}

	.p-datatable-loading-icon {
		tr {
			td {
				border: 0;
				padding: 0;
			}
		}
	}

	&.border-0 {
		.p-datatable-wrapper,
		.p-datatable-header,
		.p-datatable-footer {
			border: none;
		}
	}
}