@use 'common' as *;

.sl-input-container {
	min-width: 320px;

	.mat-icon {
		@include color(var(--sl-primary-text-icon-main));
		pointer-events: none;
	}
}
.sl-input-container.no-min-width {
	min-width: auto;
}

.sl-input {
	@include color(var(--sl-primary-text-icon-300));
	width: 100%;
	padding: 12px 16px;
	border: 1px solid var(--sl-dark-background-400);
	box-sizing: border-box;
	border-radius: 5px;
	height: 36px;
	font-size: 14px;
	outline: none;

	&.sl-input--medium {
		width: 262px;
	}

	&.sl-input--small {
		width: 210px;
	}

	&.sl-input--long {
		width: 436px;
	}

	&:disabled {
		@include color(var(--sl-secondary-text-icon-300));
	}

	&:not(:disabled) {
		&:hover, &:focus {
			border-color: var(--sl-dark-background-500);
		}
	}

	&::placeholder {
		@include color(var(--sl-secondary-text-icon-300));
	}

	&.ng-invalid:not(.ng-untouched) {
		border-color: var(--sl-alert-error-active);
	}
}
