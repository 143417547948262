/*********************************/
/***** globals CSS variables *****/
/*********************************/

/******************/
/***** colors *****/
/******************/

$sl-color-turquoise: 				#0abebe;

$sl-color-bg-primary-light:			#0f79d9;
$sl-color-bg-primary-lighter:		#208cef;

$sl-color-box-border:				#cfcfcf;

$sl-color-warning:					#8e0a0d;
$sl-color-success: 					#4f8e0a;

$sl-color-warning-2:				#ef4e4e;
$sl-color-warning-2-lighter:		#f8b4b4;

$sl-color-nav:						#37373799;

// Borders
$sl-border: 1px solid var(--sl-primary-text-icon-main);
$sl-border-radius: 5px;
$sl-border-color:					#c7c7c733;
$sl-color-table-header-cell-border:	#4a4a4a;
$sl-color-table-data-cell-border:	#eeeeee;

// Scrollbar

$sl-scrollbar-width: 5px;

// Dropdown

$sl-dropdown-width-max: 400px;

// Utilities

$sl-box-shadow: 0px 2px 5px rgba(167, 167, 167, 0.5);

// Layout

$sl-main-spacing: 32px;
$sl-main-header-height: 64px;
$sl-main-footer-height: 66px;
$sl-main-list-height: calc(100% - 64px - #{$sl-main-header-height});

$avatar-more-background-color: #6e747f;
$avatar-more-text-color: #d8dfe9;

$sl-screen-xxl: 1920px;
$sl-screen-xl: 1680px;
$sl-screen-lg: 1440px;
$sl-screen-md: 1280px;
$sl-screen-sm: 1024px;
