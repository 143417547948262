.p-sidebar {
	height: 100%;

	&.sl-sidebar-lg {
		position: absolute;
		width: calc(100% - 360px);
	}

	&.sl-sidebar-no-header {
		.p-sidebar-header {
			display: none;
		}
	}

	.p-sidebar-footer {
		padding: 0;
	}

	.p-sidebar-content {
		padding: space(3);
		overflow: auto;
	}
}