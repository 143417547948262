@use 'common' as *;

.sl-body {
	.p-inputtext.new-design {
		@include sl-typography-body;
		height: 40px;
		width: 100%;
		padding: space() space(2);
		border: 1px solid var(--sl-disabled-mode-lines-main);
		box-sizing: border-box;
		border-radius: 5px;

		&:not(:disabled):hover {
			border: $sl-border;
		}

		&::placeholder {
			@include color(var(--sl-secondary-text-icon-main));
		}
	}

	.p-input-icon-left {
		.p-inputtext.new-design {
			padding-left: space(3.5);
		}
	}

	.p-input-icon-right {
		.p-inputtext.new-design {
			padding-right: space(3.5);
		}
	}
}
