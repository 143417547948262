@use 'common' as *;

.sl-body {
	
	.mat-mdc-menu-panel {
		background: var(--sl-white-main);

		.mat-mdc-menu-content {
			padding: space();
			display: flex;
			flex-direction: column;
			gap: 8px;

			.mdc-list-item__primary-text {
				letter-spacing: normal;
				font-size: 14px;
			}
		}

		&.header-dropdown-menu {
			box-shadow: 0px 2px 8px 0px rgba(70, 81, 90, 0.18);
			&.help-menu {
				.mat-mdc-menu-item:not([disabled]) {
					padding: 0;
				}
			}

			min-height: auto;

			& button.active,
			& a.active {
				background-color: $color-background-gray-500;
			}

			.mat-mdc-menu-item {
				height: space(4);
				line-height: space(4);
				min-height: unset;
				border-radius: 6px;
				--mat-menu-item-label-text-size: 14px;
				--mat-menu-item-label-text-weight: 500;

				&.mat-menu-item-highlighted,
				&:hover {
					&:not([disabled]) {
						background-color: var(--sl-dark-background-main);
					}
				}

				&.section {
					.label {
						@include color(var(--sl-primary-text-icon-main));
						text-transform: uppercase;
					}
				}

				&.user-auth {
					background-color: $color-background-gray-500;
					border-bottom: 1px solid $color-divider-400;
					cursor: default;
					font-weight: 500;
					height: auto;
					line-height: inherit;
					padding: space();

					.mat-icon {
						@include square(16px);
						margin-right: space();

						&.sso {
							color: var(--sl-black-main);
						}
					}
				}

				&.toggle-superuser-messages {
					border-top: 1px solid $sl-border-color;
				}

				.label {
					@include color(var(--sl-primary-text-icon-main));
					cursor: pointer;

					&.section-item-label {
						display: inline-block;
						padding: 0 space(2) 0 space(3);
						text-decoration: none;
						width: 100%;

						&-flex {
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding: 0 space(2) 0 space(3);
							text-decoration: none;
							width: 100%;

							.tag {
								margin-left: space();
							}
						}
					}
				}
			}
		}
	}
}
