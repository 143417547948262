@use 'common' as *;

*,
*::after,
*::before {
	box-sizing: border-box;
}

/* Browser page */

body,
html {
	all: unset;
	display: block;
	font-family: Roboto, monospace;
	height: 100%;
	width: 100%;
	overflow: hidden
}

body {
	@include sl-fancy-scroll;
	min-height: 100%;
}

.sl-body {
	--mdc-theme-primary: var(--sl-black-main);

	&.unauthenticated-page,
	&.custom-support {
		.atlwdg-trigger {
			display: none;
		}
	}

	&.impersonated-page {
		border: 6px solid var(--sl-charts-recording-red);
		box-sizing: border-box;
	}

	// Reset
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		&.list-style-type-disc {
			list-style-type: disc;
		}
	}

	.margin-left-auto {
		margin-left: auto;
	}
	
	.sl-color-bg-secondary {
		background-color: var(--sl-secondary-brand-yellow-main);
	}

	.sl-color-background {
		@include color(var(--sl-background-main));
	}

	.sl-color-text {
		@include color(var(--sl-primary-text-icon-main));
	}

	.sl-color-text-warning {
		@include color($sl-color-warning);
	}

	.sl-color-text-success {
		@include color($sl-color-success);
	}

	.sl-color-text-secondary {
		@include color(var(--sl-secondary-text-icon-main));
	}

	.sl-text-color-primary-dark {
		@include color(var(--sl-primary-brand-active));
	}

	/*****************/
	/***** boxes *****/
	/*****************/

	.sl-box-border {
		border: 0.5px solid $sl-color-box-border;
	}

	.sl-box-shadow {
		box-shadow: 0 6px 6px rgb(0 0 0 / 25%);
	}

	/**********************/
	/***** Scrollbars *****/
	/**********************/

	.sl-fancy-scroll {
		@include sl-fancy-scroll;
	}

	.sl-overflow-y-auto {
		overflow-y: auto;
	}

	.sl-overflow-x-auto {
		overflow-x: auto;
	}

	%checked-background {
		@include square(13px);
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 2px;
		background-color: var(--sl-primary-text-icon-main);
	}

	%checked-state-indication {
		@include square(13px);
		@include color(var(--sl-white-main));
		font-family: 'Material Icons';
		position: absolute;
	}

	.styled-checkbox {
		position: relative;

		&:checked::before {
			@extend %checked-background;
		}

		&:checked::after {
			@extend %checked-state-indication;
			content: 'check';
			font-weight: 600;
		}

		&.part-selected {
			&::after {
				@extend %checked-state-indication;
				content: 'remove';
				font-weight: normal;
			}

			&::before {
				@extend %checked-background;
			}
		}

		&:disabled {
			&:checked::after {
				background-color: var(--sl-secondary-text-icon-main);
			}

			&.part-selected {
				&::after {
					background-color: var(--sl-secondary-text-icon-main);
				}
			}
		}
	}

	.sl-bullet-list {
		list-style-type: disc;
		padding: 2px space(2);
	}

	.sl-vertical-spacer {
		min-width: space(2);
		text-align: center;
		display: inline-block;

		&::after {
			content: '|';
		}
	}

	.sl-match-bold {
		@include color(var(--sl-black-main));
		font-weight: 500;
	}

	.mat-icon {
		&.mat-icon-24 {
			@include icon(24px);
		}
	
		&.mat-icon-20 {
			@include icon(20px);
		}
	
		&.mat-icon-18 {
			@include icon(18px);
		}
	
		&.mat-icon-16 {
			@include icon(16px);
		}
	
		&.mat-icon-14 {
			@include icon(14px);
		}
	
		&.mat-icon-12 {
			@include icon(12px);
		}
	}

	.white-space-nowrap {
		white-space: nowrap;
	}

	.sl-hidden {
		visibility: hidden;
	}

	.not-displayed {
		display: none;
	}

	.display-block {
		display: block;
	}

	.clickable {
		cursor: pointer;
	}

	.line-height-20 { // need to move to file of its own or to global ul
		line-height: 20px;
	}

	tbody tr {
		background-attachment: fixed !important;

		&:hover {
			background-attachment: fixed;
		}
	}

	.noselect {
		/* iOS Safari */
		-webkit-touch-callout: none;
		/* Safari */
		-webkit-user-select: none;
		/* Konqueror HTML */
		-khtml-user-select: none;
		/* Old versions of Firefox */
		-moz-user-select: none;
		/* Internet Explorer/Edge */
		-ms-user-select: none;
		/* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
		user-select: none;
	}

	.sl-center-base {
		margin: 0;
		position: absolute;
	}

	.sl-center-all {
		margin: 0;
		position: absolute;
		transform: translate(-50%, -50%);
	}

	/* Floating Toolbar */

	.floating-toolbar {
		@include box-shadow(0, 0, 10px, 0, rgba(0, 0, 0, 0.05));
		background-color: var(--sl-white-main);
		border: 1px solid $color-divider-400;
		border-radius: 4px;
		display: none;
		position: absolute;
		right: 20px;
		top: -20px;
		white-space: nowrap;
		z-index: 999;
	}

	/* Buttons */

	.primary-button {
		@include color(var(--sl-white-main));
		text-transform: none;
		background-color: var(--sl-primary-brand-active);
	}

	.warning-button {
		@include color(var(--sl-white-main));
		text-transform: none;
		background-color: var(--sl-secondary-brand-yellow-main);
	}

	.danger-button {
		@include color(var(--sl-white-main));
		text-transform: none;
		background-color: $sl-color-warning-2;
	}

	/* Effects */

	.hover-circle-effect {
		&:hover {
			background-image: radial-gradient(closest-side at 50% 80%, #f9f270, var(--sl-white-main));
			cursor: pointer;
			object-fit: contain;
		}
	}

	.panel-box-shadow {
		@include box-shadow(0, 0, 6px, 2px, rgba(0, 0, 0, 0.16));
	}

	/***  External elements specific ***/

	.sl-coverage-test-selection-dialog {
		@include box-shadow(0, 0, 10px, 5px, rgba(0, 0, 0, 0.1));
		border-radius: 10px;
		width: 40%;
		height: 80%;
		place-content: stretch stretch;

		.mat-mdc-dialog-container {
			padding-bottom: 0;
		}
	}

	.sl-table-filter-dialog {
		max-height: 400px;
		min-height: 100px;
		width: auto;

		.mat-mdc-dialog-container {
			padding: 0;
		}
	}

	/* Quality status coloring */

	.quality-status-awaiting {
		@include color($color-quality-status-awaiting);
	}

	.quality-status-incomplete {
		@include color($color-quality-status-incomplete);
	}

	.quality-status-missingData {
		@include color($color-quality-status-incomplete);
	}

	.quality-status-failed {
		@include color($color-quality-status-failed);
	}

	.quality-status-passed {
		@include color($color-quality-status-passed);
	}

	/*
	* Spin any element (including material icons)
	* Usage: <tag class="icon-spin"> or <tag [class.icon-spin]="truthy/falsy">
	*/

	.icon-spin {
		@include animation(icon-spin 2s infinite linear);
	}

	/** Transform text to ellipsis **/
	.ellipsisize {
		@include ellipsis;
		width: 100%;
	}

	.ellipsisize-reverse {
		@include ellipsis-reverse;
		width: auto;
	}

	.external-link {
		@include color($color-information);
		font-size: 12px;
		font-weight: 400;
		opacity: 0.8;
		text-decoration: none;

		&:hover {
			opacity: 1;
		}
	}

	.side-menu-test-stage-container-tabs-wrapper,
	.cockpit-root-wrapper {
		.mat-mdc-tab-group {
			@include square(100%);
			/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
			.mat-tab-body-wrapper {
				height: 100%;

				* {
					@include sl-fancy-scroll();
				}
			}
		}
	}

	.core-app-body>*:not(router-outlet) {
		flex: 1 1 100%;
		max-width: 100%;
		min-width: 100%;
		max-height: 100%;
		min-height: 100%;
	}

	/** Material dialog for notification code labels element  ***/
	.code-labels-select-list-dialog .mat-mdc-dialog-container {
		background: #ededed;
	}

	.uppercase {
		text-transform: uppercase;
	}

	.sl-width-100 {
		width: 100%;
	}

	.sl-height-100 {
		height: 100%;
	}

	.sl-width-auto {
		width: auto !important;
	}

	.sl-text-center {
		text-align: center !important;
	}

	.sl-text-left {
		text-align: left !important;
	}

	.sl-text-right {
		text-align: right !important;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.cursor-default {
		cursor: default;
	}

	.hover-container {
		.hover-element {
			visibility: hidden;
		}
		
		&:hover {
			.hover-element {
				visibility: visible;
			}
		}
	}

	.sl-link {
		@include color(var(--sl-primary-brand-pressed));
		text-decoration: none;
		cursor: pointer;
	}

	.border-radius-right-0 {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}
