:root {
	// Primary Brand
	--sl-primary-brand-100: #e5eef6;
	--sl-primary-brand-200: #d5e6f5;
	--sl-primary-brand-300: #bad9f4;
	--sl-primary-brand-400: #aeceea;
	--sl-primary-brand-500: #87aed0;
	--sl-primary-brand-active: #0a4f8e;
	--sl-primary-brand-focus: #2774b9;
	--sl-primary-brand-hover: #1464ad;
	--sl-primary-brand-pressed: #3d8ed8;

	// Secondary Brand
	--sl-secondary-brand-yellow-main: #ffce76;
	--sl-secondary-brand-yellow-100: #fff5e0;
	--sl-secondary-brand-yellow-200: #ffde99;
	--sl-secondary-brand-yellow-300: #ffbd4f;
	--sl-secondary-brand-yellow-400: #fba74b;
	--sl-secondary-brand-yellow-500: #ff8d10;

	// Background
	--sl-background-main: #f6f9fc;
	--sl-background-100: #f4f6f8;
	--sl-background-200: #eff3f6;
	--sl-background-300: #eaedf0;
	--sl-background-400: #dcdfe2;
	--sl-background-500: #fafbff;

	// Dark Background
	--sl-dark-background-main: #e7edf4;
	--sl-dark-background-100: #f2f8ff;
	--sl-dark-background-200: #ecf3fa;
	--sl-dark-background-300: #e6eef8;
	--sl-dark-background-400: #DFE7EF;
	--sl-dark-background-500: #acbdcc;

	// Accent (Disabled mode)
	--sl-disabled-mode-lines-main: #c7c7c7;
	--sl-disabled-mode-lines-100: #f6f6f6;
	--sl-disabled-mode-lines-200: #ececec;
	--sl-disabled-mode-lines-300: #c9c9c9;
	--sl-disabled-mode-lines-400: #a9a9a9;
	--sl-disabled-mode-lines-500: #9aa3ab;

	// Element Background
	--sl-element-background-main: #f8f8f8;
	--sl-element-background-100: #f2f2f2;
	--sl-element-background-200: #efefef;
	--sl-element-background-300: #ececec;
	--sl-element-background-400: #dedede;

	// White background
	--sl-white-main: #ffffff;

	// Black background
	--sl-black-main: #000000;

	// Primary Text & Icon
	--sl-primary-text-icon-main: #373737;
	--sl-primary-text-icon-100: #686868;
	--sl-primary-text-icon-200: #555555;
	--sl-primary-text-icon-300: #404040;
	--sl-primary-text-icon-400: #181818;
	--sl-primary-text-icon-500: #274056;
	--sl-primary-text-icon-600: #0b0b0b;

	// Secondary Text & Icon
	--sl-secondary-text-icon-main: #9d9d9d;
	--sl-secondary-text-icon-100: #bbbbbb;
	--sl-secondary-text-icon-200: #9d9d9d;
	--sl-secondary-text-icon-300: #747474;
	--sl-secondary-text-icon-400: #606060;

	// Alerts
	--sl-alert-error-active: #bd1e22;
	--sl-alert-error-disabled: #feedee;
	--sl-alert-error-100: #e8675e;
	--sl-alert-error-200: #ec8079;
	--sl-alert-error-300: #f39791;
	--sl-alert-error-400: #f9b5b0;
	--sl-alert-error-500: #ffe5e7;
	--sl-alert-success-active: #4d8e0a;
	--sl-alert-success-disabled: #f2fde5;
	--sl-alert-success-100: #50A144;
	--sl-alert-success-200: #96de8c;
	--sl-alert-success-300: #bef9b5;
	--sl-alert-success-400: #f2fde5;

	// Charts colors
	--sl-charts-teal: #61a4b0;
	--sl-charts-light-teal: #a3cad1;
	--sl-charts-pink: #e7aeae;
	--sl-charts-light-pink: #efc9c9;
	--sl-charts-brown: #cec0a6;
	--sl-charts-light-brown: #ded5c4;
	--sl-charts-pale-blue: #a6b4ce;
	--sl-charts-light-pale-blue: #c4cdde;
	--sl-charts-positive-green: #b9ddb4;
	--sl-charts-hyper-links: #3ac4ff;
	--sl-charts-loader-blink: #e4ff3d;
	--sl-charts-recording-red: #da2f22;
	--sl-charts-chip-1: #5488b7;
	--sl-charts-chip-2: #84bfd1;
}
