@use 'common' as *;

.sl-logo {
        background-image: $image-logo-small;
        background-position: center left;
        background-repeat: no-repeat;
        background-size: contain;
        height: 30px;
        width: 110px;
}

.sl-logo-header { 
        padding-top: space(3);
        display: flex;
        align-items:center;
        justify-content:center;

        .sl-logo-seperator{
                border-right: 1px solid gray;
		height: space(3);
		margin: 0 space(1.5);
        }

        .sl-logo-title{
                white-space: nowrap;
		text-align: center;
        }
}