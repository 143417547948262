@use 'common' as *;

.sl-body {
	.p-button.new-design {
		@include sl-typography-body-bold;
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		height: 40px;
		border-radius: 4px;
		outline: none;
		box-shadow: none;

		&:hover {
			background: none;
		}

		.p-button-icon {
			font-size: 10px;
			font-weight: 700;
		}

		&.primary {
			@include color(var(--sl-primary-brand-active));

			&.p-button-outlined {
				border: 1px solid var(--sl-primary-brand-active);
			}
		}
	}
}
