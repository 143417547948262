@use 'common' as *;
@use 'mixins' as *;

.p-inputwrapper {
    height: 36px;

    .p-inputnumber-input {
        @include sl-v1-field;
        @include sl-v1-input;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-inputnumber-button {
        border: 1px solid var(--sl-secondary-text-icon-100);
        border-left: 0;
        background: var(--sl-white-main);
        width: 16px;
        color: var(--sl-primary-text-icon-300);

        &.p-inputnumber-button-up {
            border-radius: 0px 6px 0px 0px;
            border-bottom: 0;
        }


        &.p-inputnumber-button-down {
            border-radius: 0px 0px 6px 0px;
        }



        .p-icon-wrapper {
            width: 12px;
        }
    }
    
}