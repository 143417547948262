@use './flex-mixins.scss' as *;

.sl-body {

	.sl-flexbox {
		@include sl-flexbox;
	}

	.sl-flexbox-inline {
		@include sl-flexbox-inline;
	}

	.sl-flexbox-direction-row {
		@include sl-flexbox-direction-row;
	}

	.sl-flexbox-direction-row-reverse {
		@include sl-flexbox-direction-row-reverse;
	}

	.sl-flexbox-direction-column {
		@include sl-flexbox-direction-column;
	}

	.sl-flexbox-direction-column-reverse {
		@include sl-flexbox-direction-column-reverse;
	}

	.sl-flexbox-row {
		@include sl-flexbox-row;

		&.sl-flexbox-flex-dynamic,
		&.sl-flexbox-flex-static,
		.sl-flexbox-flex-dynamic,
		.sl-flexbox-flex-static {
			max-width: 100%;
			min-width: 1%;
		}
	}

	.sl-flexbox-row-reverse {
		@include sl-flexbox-row-reverse;
	}

	.sl-flexbox-inline-row {
		@include sl-flexbox-inline-row;
	}

	.sl-flexbox-column {
		@include sl-flexbox-column;

		&.sl-flexbox-flex-dynamic,
		&.sl-flexbox-flex-static,
		.sl-flexbox-flex-dynamic,
		.sl-flexbox-flex-static {
			max-height: 100%;
			min-height: 1%;
		}
	}

	.sl-flexbox-column-reverse {
		@include sl-flexbox-column-reverse;
	}

	.sl-flexbox-shrink {
		@include sl-flexbox-shrink;
	}

	.sl-flexbox-grow {
		@include sl-flexbox-grow;
	}

	.sl-flexbox-justify-center {
		@include sl-flexbox-justify-center;
	}

	.sl-flexbox-justify-end {
		@include sl-flexbox-justify-end;
	}

	.sl-flexbox-justify-start {
		@include sl-flexbox-justify-start;
	}

	.sl-flexbox-justify-stretch {
		@include sl-flexbox-justify-stretch;
	}

	.sl-flexbox-justify-space-between {
		@include sl-flexbox-justify-space-between;
	}

	.sl-flexbox-justify-space-around {
		@include sl-flexbox-justify-space-around;
	}

	.sl-flexbox-justify-space-evenly {
		@include sl-flexbox-justify-space-evenly;
	}

	.sl-flexbox-self-justify-center {
		@include sl-flexbox-self-justify-center;
	}

	.sl-flexbox-self-justify-end {
		@include sl-flexbox-self-justify-end;
	}

	.sl-flexbox-self-justify-start {
		@include sl-flexbox-self-justify-start;
	}

	.sl-flexbox-self-justify-stretch {
		@include sl-flexbox-self-justify-stretch;
	}

	.sl-flexbox-self-justify-space-between {
		@include sl-flexbox-self-justify-space-between;
	}

	.sl-flexbox-self-justify-space-around {
		@include sl-flexbox-self-justify-space-around;
	}

	.sl-flexbox-self-justify-space-evenly {
		@include sl-flexbox-self-justify-space-evenly;
	}

	.sl-flexbox-align-center {
		@include sl-flexbox-align-center;
	}

	.sl-flexbox-align-end {
		@include sl-flexbox-align-end;
	}

	.sl-flexbox-align-start {
		@include sl-flexbox-align-start;
	}

	.sl-flexbox-align-stretch {
		@include sl-flexbox-align-stretch;
	}

	.sl-flexbox-align-space-between {
		@include sl-flexbox-align-space-between;
	}

	.sl-flexbox-align-space-around {
		@include sl-flexbox-align-space-around;
	}

	.sl-flexbox-align-space-evenly {
		@include sl-flexbox-align-space-evenly;
	}

	.sl-flexbox-self-align-center {
		@include sl-flexbox-self-align-center;
	}

	.sl-flexbox-self-align-end {
		@include sl-flexbox-self-align-end;
	}

	.sl-flexbox-self-align-start {
		@include sl-flexbox-self-align-start;
	}

	.sl-flexbox-self-align-stretch {
		@include sl-flexbox-self-align-stretch;
	}

	.sl-flexbox-self-align-space-between {
		@include sl-flexbox-self-align-space-between;
	}

	.sl-flexbox-self-align-space-around {
		@include sl-flexbox-self-align-space-around;
	}

	.sl-flexbox-self-align-space-evenly {
		@include sl-flexbox-self-align-space-evenly;
	}

	.sl-flexbox-flex-dynamic {
		@include sl-flexbox-flex-dynamic;
	}

	.sl-flexbox-flex-expandable {
		@include sl-flexbox-flex-expandable;
	}

	.sl-flexbox-flex-static {
		@include sl-flexbox-flex-static;
	}

	.sl-flexbox-basis-auto {
		@include sl-flexbox-basis-auto;
	}

	.sl-flexbox-wrap {
		@include sl-flexbox-wrap;
	}

	.sl-flexbox-nowrap {
		@include sl-flexbox-nowrap;
	}

	@each $size in $sl-flexbox-basis-sizes {
		.sl-flexbox-basis-#{$size} {
			@include sl-flexbox-basis($size);
		}
	}

	@each $size in $sl-flexbox-gap-sizes {
		.sl-flexbox-gap-#{$size} {
			@include sl-flexbox-gap($size);
		}
	}
}
