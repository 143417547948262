$sl-flexbox-gap-sizes:
	0,
	2,
	4,
	8,
	12,
	16,
	20,
	24,
	28,
	32,
	36,
	40,
	44,
	48,
	52,
	64,
	5,
	10,
	15,
	25,
	30,
	35,
	45,
	50;

$sl-flexbox-basis-sizes:
	0,
	25,
	33,
	50,
	66,
	75,
	100;

@mixin sl-flexbox-gap($gap) {
	gap: #{$gap+'px'};
}

@mixin sl-flexbox-basis($basis) {
	flex-basis: #{$basis+ '%'};
}

@mixin sl-flexbox {
	display: flex;
}

@mixin sl-flexbox-inline {
	display: inline-flex;
}

@mixin sl-flexbox-direction-row {
	flex-direction: row;
}

@mixin sl-flexbox-direction-row-reverse {
	flex-direction: row-reverse;
}

@mixin sl-flexbox-direction-column {
	flex-direction: column;
}

@mixin sl-flexbox-direction-column-reverse {
	flex-direction: column-reverse;
}

@mixin sl-flexbox-row {
	@include sl-flexbox;
	@include sl-flexbox-direction-row;
}

@mixin sl-flexbox-row-reverse {
	@include sl-flexbox;
	@include sl-flexbox-direction-row-reverse;
}

@mixin sl-flexbox-inline-row {
	@include sl-flexbox-inline;
	@include sl-flexbox-direction-row;
}

@mixin sl-flexbox-column {
	@include sl-flexbox;
	@include sl-flexbox-direction-column;
}

@mixin sl-flexbox-column-reverse {
	@include sl-flexbox;
	@include sl-flexbox-direction-column-reverse;
}

@mixin sl-flexbox-shrink {
	flex-shrink: 1;
}

@mixin sl-flexbox-grow {
	flex-grow: 1;
}

@mixin sl-flexbox-justify-center {
	justify-content: center;
	justify-items: center;
}

@mixin sl-flexbox-justify-end {
	justify-content: flex-end;
	justify-items: flex-end;
}

@mixin sl-flexbox-justify-start {
	justify-content: flex-start;
}

@mixin sl-flexbox-justify-stretch {
	justify-content: stretch;
	justify-items: stretch;
}

@mixin sl-flexbox-justify-space-between {
	justify-content: space-between;
}

@mixin sl-flexbox-justify-space-around {
	justify-content: space-around;
}

@mixin sl-flexbox-justify-space-evenly {
	justify-content: space-evenly;
}

@mixin sl-flexbox-self-justify-center {
	justify-self: center;
}

@mixin sl-flexbox-self-justify-end {
	justify-self: flex-end;
}

@mixin sl-flexbox-self-justify-start {
	justify-self: flex-start;
}

@mixin sl-flexbox-self-justify-stretch {
	justify-self: stretch;
}

@mixin sl-flexbox-self-justify-space-between {
	justify-self: space-between;
}

@mixin sl-flexbox-self-justify-space-around {
	justify-self: space-around;
}

@mixin sl-flexbox-self-justify-space-evenly {
	justify-self: space-evenly;
}

@mixin sl-flexbox-align-center {
	align-content: center;
	align-items: center;
}

@mixin sl-flexbox-align-end {
	align-content: flex-end;
	align-items: flex-end;
}

@mixin sl-flexbox-align-start {
	align-content: flex-start;
	align-items: flex-start;
}

@mixin sl-flexbox-align-stretch {
	align-content: stretch;
	align-items: stretch;
}

@mixin sl-flexbox-align-space-between {
	align-content: space-between;
}

@mixin sl-flexbox-align-space-around {
	align-content: space-around;
}

@mixin sl-flexbox-align-space-evenly {
	align-content: space-evenly;
}

@mixin sl-flexbox-self-align-center {
	align-self: center;
}

@mixin sl-flexbox-self-align-end {
	align-self: flex-end;
}

@mixin sl-flexbox-self-align-start {
	align-self: flex-start;
}

@mixin sl-flexbox-self-align-stretch {
	align-self: stretch;
}

@mixin sl-flexbox-self-align-space-between {
	align-self: space-between;
}

@mixin sl-flexbox-self-align-space-around {
	align-self: space-around;
}

@mixin sl-flexbox-self-align-space-evenly {
	align-self: space-evenly;
}

@mixin sl-flexbox-flex-dynamic {
	flex: 1 1 auto;
	width: auto;
}

@mixin sl-flexbox-flex-expandable {
	flex: 0 1 auto;
	width: auto;
}

@mixin sl-flexbox-flex-static {
	flex: 0 0 auto;
	width: auto;
}

@mixin sl-flexbox-basis-auto {
	flex-basis: auto;
}

@mixin sl-flexbox-wrap {
	flex-wrap: wrap;
}

@mixin sl-flexbox-nowrap {
	flex-wrap: nowrap;
}
