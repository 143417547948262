.tia-settings-table {
	table {
        tbody {
            tr {
                &:first-child {
                    background-color: var(--sl-disabled-mode-lines-100);
        
                    &:hover {
                        background-color: var(--sl-disabled-mode-lines-100)!important;
                    }
                }
            }
        }
    }
}