@use 'common' as *;

.sl-v1-accordion {
	.p-accordion-header {
		.p-accordion-header-link {
			padding: 10px;
			background: var(--sl-background-main);
			border-color: var(--sl-disabled-mode-lines-200);
			border-radius: 6px;
			color: var(--sl-black-main);
		}
	}

	.p-accordion-tab-active {
		.p-accordion-header-link {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.p-accordion-content {
		border-radius: 6px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	p-badge {
		height: 10px;
	}

	&.max-width-250 {
		max-width: 250px;
	}

    &.sl-v1-accordion--md {
        
        .p-accordion-header-link {
            padding: space() space(2) space() 0;
            border: unset;
            background: unset;
            font-weight: unset;
            border-radius: unset;
            transition: unset;

            .p-accordion-toggle-icon {
                @include color(var(--sl-secondary-text-icon-main));
                font-size: 12px;
            }

            .p-accordion-header-text {
                @include sl-typography-body;
            }
        }

        .p-accordion-tab {
            margin-bottom: space(3);

            .p-toggleable-content {
                @include sl-fancy-scroll();
                overflow-y: auto;
                max-height: 900px;
            }
        }

        .p-accordion-content {
            padding: space() space(3);
            border: unset;
            background: unset;
            border-top: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &.sl-v1-accordion--lg {

        .p-accordion-tab {
            position: relative;
            border: 1px solid var(--sl-disabled-mode-lines-200);
            border-width: 0 0 1px 0;
            border-color: var(--sl-disabled-mode-lines-200);
            padding: 15px 0;
        }

        .p-accordion-header {
            display: flex;
            justify-content: flex-start;
            
            .p-accordion-header-link {
                color: var(--sl-primary-text-icon-300);
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                border-radius: 0;
                padding: 12px 24px 12px 0;
                background: unset;
                border: 0;
            }
        }

        .p-accordion-content {
            border: none;
            padding: 12px 24px;
            line-height: 1.4;
        }
    }
}