@use 'common' as *;

.sl-body {
	.p-overlaypanel {
		background-color: var(--sl-white-main);
		border-radius: 0 0 space(0.5) space(0.5);
		box-shadow: $sl-box-shadow;
		box-sizing: content-box;
		margin-left: 5px;
		margin-top: space(1);

		&.help-panel {
			background-color: var(--sl-white-main);
			margin-top: 0;
			width: 240px;
			border-radius: 6px;
			border: 0.5px solid var(--sl-dark-background-400);
			background: var(--sl-white-main);
			box-shadow: 0px 2px 10px 0px var(--sl-disabled-mode-lines-main);
			padding: 16px;
		
			&.simple {
				margin-top: 6px;
				padding: 8px;
				width: auto;
				border-color: var(--sl-dark-background-200);
				box-shadow: 0px 4px 8px -2px rgba(57, 60, 67, 0.16);
			}
			
			&.medium {
				width: 300px;
			}

			&.wide {
				width: 480px;
			}

			.p-overlaypanel-content {
				font-size: 14px;
				margin-top: 0;
				padding: 0;

				.section-header {
					&.no-margin-above {
						margin-top: 0;
					}

					@include sl-typography-body-bold;
					margin: space(3) 0 space() 0;
				}

				ul {
					list-style-type: disc;
					padding-left: space(2);
				}
			}
		}

		&.code-labels-panel {
			width: auto;
			background-color: var(--sl-white-main);
			margin-top: 0;
			padding: space(2);



			.p-overlaypanel-content {
				font-size: 14px;
				margin-top: 0;
				padding: 0;

				& > div {
					padding-right: space(2);
				}
			}
		}

		&::after {
			content: unset;
		}

		&::before {
			content: unset;
		}

		.p-overlaypanel-close {
			@include color(var(--sl-primary-text-icon-300));
			@include square(14px);
			background: unset;
			top: 18px;
			right: 18px;

			&:enabled:hover {
				background: unset;
				@include color(var(--sl-secondary-text-icon-main));
			}

			&:focus {
				box-shadow: none;
			}

		}

		.p-overlaypanel-content {
			margin-top: space(4);
			padding: 0 0 space(2) 0;
		}
	}
}
