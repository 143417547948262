.p-inline-message.sl-v1 {
	width: 100%;
	justify-content: flex-start;
	margin: 16px 0;
	background-color: var(--sl-secondary-brand-yellow-100);
	border-left: 6px #ffbd50 solid;
	border-radius: 6px;

	.p-inline-message-text {
		@include color(var(--sl-primary-text-icon-main));
		font-weight: 400;
		font-size: 14px;
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
}

