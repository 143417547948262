@use 'mixins' as *;

.sl-dropdown-button {
	.p-button {
		@include button-base;

		padding: 4px 24px;

		&.p-splitbutton-defaultbutton {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: 1px solid rgba(255, 255, 255, 0.2);
		}

		&.p-splitbutton-menubutton {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			padding-right: 8px;
			padding-left: 8px;
		}
	}

	&.sl-dropdown-button--disabled {
		.p-button {
			@include color(var(--sl-secondary-text-icon-100));

			background-color: var(--sl-disabled-mode-lines-200);
			opacity: 1;
		}
	}

	&--regular {
		.p-button {
			background-color: var(--sl-primary-brand-active);

			&:hover,
			&:focus {
				background-color: var(--sl-primary-brand-hover);
			}

			&:active {
				background-color: var(--sl-primary-brand-focus);
			}
		}
	}

	&--warning {
		.p-button {
			background-color: var(--sl-alert-error-100);

			&:hover,
			&:focus {
				background-color: var(--sl-alert-error-200);
			}

			&:active {
				background-color: var(--sl-alert-error-300);
			}
		}
	}

	&-menu {
		box-shadow: none;
		min-height: auto;
		border: 0;

		.p-tieredmenu-root-list,
		.p-submenu-list {
			border: 0;
			border-radius: 4px;
			box-shadow: 0px 2px 8px 0px rgba(70, 81, 90, 0.18);
			padding: 8px;
			gap: 8px;
		}

		.p-menuitem {
			margin-bottom: 8px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.p-menuitem-separator {
			margin-bottom: 8px;
		}

		.p-menuitem-content {
			height: 32px;
			border-radius: 6px;
			background-color: white;

			&:hover {
				background-color: var(--sl-dark-background-main);
			}
		}

		.p-menuitem-link {
			@include color(var(--mat-menu-item-label-text-color));
			padding: 8px 16px;
			font-size: 14px;
			min-height: unset;
			letter-spacing: 0.2px;
		}
	}
}
