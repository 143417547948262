@use 'common' as *;

.sl-v1-unified-list {
	.sl-v1-list-filter {
		margin-left: 8px;

		.p-dropdown.sl-v1-dropdown,
		.p-multiselect.sl-v1-multiselect {
			border-radius: 6px;
		}
	}
}