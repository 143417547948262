@use 'common' as *;

.sl-body {
	.mat-stepper-horizontal {
		.mat-horizontal-stepper-header-container {
			.mat-step-header {
				padding: 0;

				&:hover,
				&.cdk-program-focused,
				&.cdk-keoboard-focused {
					background-color: unset;
				}

				.mat-step-icon {
					&.mat-step-icon-selected,
					&.mat-step-icon-state-edit {
						background-color: $color-primary-button;
						color: var(--sl-black-main);
					}
				}

				.mat-step-label {
					opacity: 0.7;

					&.mat-step-label-selected {
						font-size: 14px;
						font-weight: 500;
						opacity: 1;
					}
				}
			}

			.mat-stepper-horizontal-line {
				margin: 0 space(2);
				max-width: space(6);
			}
		}
	}
}
