// All measures are expressed in rems instead of pixels
@use 'sass:math';

$prefix: 'sl';

$sizes: (
	12,
	16,
	20,
	24,
	26,
	28,
	32,
	36,
	40,
	48,
	56,
	60,
	64,
	80,
	84,
	92,
	100,
	105,
	120,
	132,
	140,
	160,
	180,
	200,
	210,
	220,
	240,
	280,
	320,
	360,
	400,
	435,
	460,
	480,
	560,
	640,
	720,
	800,
	960,
	1120,
	1280,
	1440,
	1600,
	1760,
	1920
);

$percentages: (
	10,
	20,
	25,
	40,
	50,
	60,
	75,
	80,
	100
);

@each $size in $sizes {

	.sl-body .w-#{$size} {
		width: #{$size+'px'};
	}

	.w-min-#{$size} {
		min-width: #{$size+'px'};
	}

	.w-max-#{$size} {
		max-width: #{$size+'px'};
	}

	.h-#{$size} {
		height: #{$size+'px'};
	}

	.h-min-#{$size} {
		min-height: #{$size+'px'};
	}

	.h-max-#{$size} {
		max-height: #{$size+'px'};
	}

}

@each $percent in $percentages {
	.w-percent-#{$percent} {
		width: #{$percent+'%'};
	}

	.w-percent-min-#{$percent} {
		min-width: #{$percent+'%'};
	}

	.w-percent-max-#{$percent} {
		max-width: #{$percent+'%'};
	}

	.h-percent-#{$percent} {
		height: #{$percent+'%'};
	}

	.h-percent-min-#{$percent} {
		min-height: #{$percent+'%'};
	}

	.h-percent-max-#{$percent} {
		max-height: #{$percent+'%'};
	}
}
