@use 'common' as *;

.ui-treetable-table {
	.ui-treetable-tbody {
		tr {
			td {
				.match {
					background-color: $color-text-highlight;
				}
			}
		}
	}
}
