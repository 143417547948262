@use 'common' as *;

.sl-body {
	p-avatar {
		@include circle(28px);

		&:not(.rest-avatar) .p-avatar-text {
			@include color(var(--sl-white-main));
		}

		.p-avatar-text {
			font-size: 12px;
			font-weight: 500;
			line-height: 1;
		}

		.avatar-more.p-avatar.p-component.p-avatar-circle {
			@include color($avatar-more-text-color);
			background-color: $avatar-more-background-color;
		}

		.avatar-0 {
			background-color: #fc7d63;
		}

		.avatar-1 {
			background-color: #ba6577;
		}

		.avatar-2 {
			background-color: #f29212;
		}

		.avatar-3 {
			background-color: #53b3b3;
		}
	}

	p-avatargroup {
		.p-avatar-group p-avatar+p-avatar {
			margin-left: space(-0.6);
		}
	}
}
