/* ANIMATIONS */

@keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@-webkit-keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

/** heartbeat **/
@keyframes heartbeat {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.2);
	}

	40% {
		transform: scale(1);
	}

	60% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

@-webkit-keyframes heartbeat {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.2);
	}

	40% {
		transform: scale(1);
	}

	60% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes gradient {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100%;
	}
	100% {
		background-position: 0%;
	}
}

@-webkit-keyframes gradient {
	0% {
		background-position: 0%;
	}
	50% {
		background-position: 100%;
	}
	100% {
		background-position: 0%;
	}
}

@keyframes expandStroke {
	35% {
		stroke-dasharray: 0, 85;
	}
	40% {
		stroke-dasharray: 81, 81;
	}
	95% {
		stroke-dasharray: 81, 81;
	}
	100% {
		stroke-dasharray: 0, 85;
	}
}

@-webkit-keyframes expandStroke {
	35% {
		stroke-dasharray: 0, 85;
	}
	40% {
		stroke-dasharray: 81, 81;
	}
	95% {
		stroke-dasharray: 81, 81;
	}
	100% {
		stroke-dasharray: 0, 85;
	}
}

@keyframes rotateIcon {
	30% {
		transform: rotate(345deg);
	}
	100% {
		transform: rotate(345deg);
	}
}

@-webkit-keyframes rotateIcon {
	30% {
		transform: rotate(345deg);
	}
	100% {
		transform: rotate(345deg);
	}
}

@keyframes shrinkIcon {
	10% {
		transform: scale(1);
	}
	15% {
		transform: scale(0.5);
	}
	20% {
		transform: scale(1);
	}
}

@-webkit-keyframes shrinkIcon {
	10% {
		transform: scale(1);
	}
	15% {
		transform: scale(0.5);
	}
	20% {
		transform: scale(1);
	}
}

@keyframes text-focus-in {
	0% {
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		filter: blur(0px);
		opacity: 1;
	}
}

@-webkit-keyframes text-focus-in {
	0% {
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		filter: blur(0px);
		opacity: 1;
	}
}

@keyframes text-blur-out {
	0% {
		filter: blur(0.01);
	}
	100% {
		filter: blur(12px) opacity(0%);
	}
}

@-webkit-keyframes text-blur-out {
	0% {
		filter: blur(0.01);
	}
	100% {
		filter: blur(12px) opacity(0%);
	}
}

@keyframes blinkOpacity {
	0% {
		opacity: 1;
	}

	25% {
		opacity: 0.8;
	}

	40% {
		opacity: 0.4;
	}

	60% {
		opacity: 0.8;
	}

	100% {
		opacity: 1;
	}
}

@keyframes pulsateOpacity {
	0%,
	50%,
	100% {
		opacity: 1;
	}
	25%,
	75% {
		opacity: 0.3;
	}
}
