@use 'common' as *;

.sl-v1-tabview.p-tabview {
	.p-tabview-nav-container {
		border-bottom: 1px solid var(--sl-disabled-mode-lines-main);
		position: relative;
	}

	.p-tabview-nav li.p-highlight .p-tabview-nav-link {
		@include color(var(--sl-primary-brand-active));
		border-bottom: 1px solid var(--sl-primary-brand-active);
	}

	.p-tabview-nav li .p-tabview-nav-link {
		@include color(var(--sl-secondary-text-icon-main));
		background: none;
		border: none;
		font-weight: 500;
		font-size: 14px;
		padding: space();
	}
}

.sl-v2-tabview.p-tabview {
	height: 100%;
	display: flex;
	flex-direction: column;

	.p-tabview-panel {
		height: 100%;
		width: 100%;
	}

	.p-tabview-panels {
		border: 1px solid var(--sl-disabled-mode-lines-main);
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		padding: 0;
		height: 100%;
		overflow: hidden;
	}

	.p-tabview-nav-container {
		border-left: 1px solid var(--sl-disabled-mode-lines-main);
		border-right: 1px solid var(--sl-disabled-mode-lines-main);
		position: relative;

		.p-tabview-nav {
			background: var(--sl-background-100);

			li.sl-v2-tab-panel {
				border-top: 1px solid #cfdae5;
				border-bottom: 1px solid #cfdae5;
				margin-right: 0;

				&:last-of-type {
					border-right: 1px solid #cfdae5;
					border-top-right-radius: 6px;
				}

				&:first-of-type {
					&.p-highlight .p-tabview-nav-link,
					.p-tabview-nav-link {
						border-radius: 0 6px 0 0;
					}
				}

				&:not(:last-of-type) {
					.p-tabview-nav-link {
						border-width: 1px;
						border-style: solid;
						border-image: linear-gradient(to bottom, transparent, #b7c3ce, transparent) 0 1 0 0;
					}
				}

				&.p-highlight {
					.p-tabview-nav-link {
						@include color(var(--sl-primary-brand-active));
						border-bottom: 1px solid var(--sl-primary-brand-active);
						font-family: Roboto;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						background: white;
						border-radius: 6px 6px 0px 0px;
						box-shadow:
							-2px 0px 4px 0px rgba(0, 0, 0, 0.08),
							2px -1px 4px 0px rgba(0, 0, 0, 0.08);

						&:hover {
							background: var(--sl-dark-background-100);
						}
					}
				}

				.p-tabview-nav-link {
					@include color(var(--sl-disabled-mode-lines-500));
					background: none;
					border: none;
					font-family: Roboto;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%;
					height: 42px;

					&:hover {
						background: var(--sl-background-100);
					}
				}
			}
		}
	}
}
