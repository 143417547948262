.sl-body {
	.p-accordion.new-design {
		.p-accordion-tab.p-accordion-tab-active .p-accordion-header.p-highlight .p-accordion-header-link {
			border-color: #c8c8c8;
			background: var(--sl-background-main);
		}

		.p-accordion-toggle-icon-end {
			@include color(#333333);
		}

		&.scrollable {
			overflow-y: auto;
		}

		&.h-600 {
			height: 600px;
		}

		&.w-600 {
			width: 600px;
		}

		&.h-300 {
			height: 400px;
		}

		&.w-400 {
			width: 400px;
		}
	}
}
