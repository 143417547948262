@import '../mixins';

.mat-icon {
	&.mat-icon-btn {
		cursor: pointer;
	}

	&.lg {
		@include square(16px);
		font-size: 14px;
	}

	&.sm {
		@include square(14px);
		font-size: 14px;
	}

	&.xs {
		@include square(12px);
		font-size: 12px;
	}

	&.search {
		height: 12px;
		width: 18px;
		font-size: 14px;
	}

	&.disabled {
		opacity: .2;
	}
}
