@use 'common' as *;

.sl-body {
	.mat-mdc-form-field {
		--mdc-typography-body1-font-size: 14px;
		--mdc-typography-body1-letter-spacing: normal;

		&.mat-focused {
			&.mat-primary {
				&.mat-focused {
					/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
					.mat-select-arrow {
						color: var(--sl-black-main);
					}
				}
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-label {
				color: inherit;
				-webkit-text-fill-color: inherit;
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-underline {
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-ripple {
					background-color: $color-divider-400;
				}
			}
		}
	}

	.mat-mdc-form-field-focus-overlay {
		background-color: transparent;
	}

	.mat-mdc-form-field-subscript-wrapper {
		--mdc-typography-caption-font-size: 11px;
	}

	.mat-mdc-form-field-hint-wrapper {
		padding: 0;
	}

	.mat-mdc-form-field-error-wrapper {
		padding: 0;
	}
}
