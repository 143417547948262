@use 'common' as *;

.sl-body {
	.p-listbox.new-design {

		&.no-border {
			border: none;
		}

		.p-listbox-list .p-listbox-item.p-highlight:not(:hover) {
			background: none;
		}

		.p-listbox-list .p-listbox-item:hover {
			background: var(--sl-background-main);
		}

		.p-listbox-list .p-listbox-item:focus {
			box-shadow: none;
		}

		&.no-filter-border {
			.p-listbox-header {
				border-bottom: 0;
				margin-bottom: 8px;
			}
		}
	}

	.p-listbox.sl-v1 {
		.p-listbox-item {
			.only {
				display: none;
			}

			&:hover {
				.only {
					margin-left: space(1);
					display: block;
				}
			}
		}
	}
}