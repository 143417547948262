@use 'common' as *;
@use 'mixins' as *;

.sl-v1-dropdown,
.sl-v1-multiselect,
.sl-v1-autocomplete {
	@include sl-v1-field;
	min-width: 160px;
	max-width: 320px;

	&.full-width {
		max-width: unset;
	}

	&.sl-v1-dropdown_textual,
	&.sl-v1-multiselect_textual,
	&.sl-v1-autocomplete_textual {
		min-width: unset;
		max-width: unset;
		border-color: transparent !important;
		text-decoration: underline;
		background: none;

		.p-dropdown-label,
		.p-autocomplete-label,
		.p-multiselect-label {
			max-width: unset;
		}
	}

	.p-dropdown-label,
	.p-autocomplete-label,
	.p-multiselect-label {
		text-overflow: ellipsis;
		overflow: hidden;

		span:last-child {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.p-dropdown-open, 
	&.p-autocomplete-open, 
	&.p-multiselect-open  {
		@include sl-v1-dropdown-open;

		.p-dropdown-panel {
			@include sl-v1-dropdown-open-panel;
		}

		.p-dropdown-trigger, 
		.p-autocomplete-dropdown, 
		.p-multiselect-trigger {
			transform: scaleY(-100%);
		}
	}

	&:not(.p-disabled).p-focus {
		box-shadow: none;
		// border: 1px solid var(--sl-secondary-text-icon-100);border
	}

	// .p-multiselect-label-container, 
	// .p-dropdown-label-container,
	// .p-autocomplete-multiple-container {
	// 	overflow: hidden;
	// 	flex: 1 1 auto;
	// 	cursor: pointer;
	// 	display: flex;
	// 	border: none;
	// 	box-shadow: none;
	// }

	.p-autocomplete-multiple-container {
		@include sl-fancy-scroll();
		flex-flow: wrap;
		max-height: 200px;
		overflow: auto;
		width: 100%;
		align-items: flex-start;
		box-shadow: none;
		border: none;
		padding: 4px 8px;
		gap: 4px;
	}

	.p-dropdown-label,
	.p-multiselect-label,
	.p-autocomplete-input {
		@include sl-v1-input;
		@include color(var(--sl-primary-text-icon-main));
		font-size: 14px;
		padding-right: 0;
		height: 100%;
		border: none;
		background: none;
		
		&.p-placeholder {
			@include color(var(--sl-secondary-text-icon-300));
		}

		.mat-icon {
			height: 14px;
			width: 16px;
			min-width: 16px;
			overflow: hidden;
			font-size: 16px;
			margin-right: 8px;
		}
	}

	.p-dropdown-trigger,
	.p-autocomplete-dropdown,
	.p-multiselect-trigger {
		color: #848484;
		width: 36px;
		margin-right: 8px;
		background: none;
		border: none;
		box-shadow: none;

		.p-dropdown-trigger-icon, 
		.p-multiselect-trigger-icon,
		.p-icon-wrapper {
			width: 10px;
			font-size: 14px;
			stroke: var(--sl-primary-text-icon-300);
			stroke-width: 0.3;
		}
	}

	&:not(.p-disabled):not(.p-dropdown-open):hover,
	&:not(.p-disabled):not(.p-autocomplete-open):hover,
	&:not(.p-disabled):not(.p-multiselect-open):hover {
		border-color: var(--sl-dark-background-500);
	}

	&:not(.p-disabled):hover {
		border-color: var(--sl-dark-background-500);
	}

	.p-overlay {
		top: 100% !important;
		left: -1px !important;
	}

	.p-dropdown-clear-icon {
		position: unset;
		margin-top: 6px;
	}

	.p-multiselect-clear-icon {
		height: 15px;
	}

	.p-multiselect-panel {
		@include sl-v1-dropdown-open-panel;

		.p-multiselect-header {
			padding: 16px;
		}
	}

	.p-multiselect-items {
		padding: 8px;
	}

	.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
		border-radius: 4px;

		&:hover {
			background: var(--sl-dark-background-main);

			.only {
				display: block;
			}
		}

		&.p-highlight {
			color: unset;
			background: unset;

			&:hover {
				background: var(--sl-dark-background-main);
			}
		}
	}

	.p-multiselect-close {
		display: none;
	}

	.p-multiselect-filter {
		@include sl-v1-filter;
	}

	.p-multiselect-filter-icon {
		@include sl-v1-filter-icon;
	}
}

.sl-v1-dropdown-panel,
.sl-v1-multiselect-panel,
.sl-v1-autocomplete-panel {
	margin-top: -1px;
	@include sl-fancy-scroll();

	.p-dropdown-header,
	.p-multiselect-header,
	.p-autocomplete-header {
		padding: 12px 16px;

		.p-dropdown-filter,
		.p-multiselect-filter,
		.p-autocomplete-filter {
			@include sl-v1-filter;
		}

		.p-dropdown-filter-icon,
		.p-multiselect-filter-icon,
		.p-autocomplete-filter-icon {
			@include sl-v1-filter-icon;
		}
	}

	.dropdown-footer,
	.p-dropdown-footer {
		// TODO: first class will probably be deprecated by primeNG;
		padding: 12px 16px;
		border-top: 1px solid var(--sl-dark-background-main);

		mat-spinner {
			margin: 0;
		}
	}

	.p-dropdown-items-wrapper,
	.p-multiselect-items-wrapper,
	.p-autocomplete-items-wrapper {
		@include sl-fancy-scroll();
	} 

	.p-dropdown-items,
	.p-multiselect-items,
	.p-autocomplete-items {
		padding: 0 8px;
		padding: 8px 0;

		> .p-element {
			&:last-child {
				.p-dropdown-item {
					margin-bottom: 0;
				}
			}
		}

		.p-dropdown-item,
		.p-multiselect-item,
		.p-autocomplete-item {
			height: auto;
			min-height: 32px;
			border-radius: $sl-border-radius;
			margin: 0 8px;
			display: flex;
			align-items: center;
			font-size: 14px;
			margin-bottom: 1px;
			padding: 8px;
			line-height: 16px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			span,
			div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.hoverable {
				visibility: hidden;
			}

			.dropdown-item-radio {
				width: 14px;
				height: 14px;
				border-radius: 50%;
				display: inline-block;
				border: $sl-border;
				align-items: center;
				justify-content: center;
				margin: 0 space(1);
				padding: 2px;

				.p-highlight & {
					&:before {
						content: '';
						height: 100%;
						width: 100%;
						background-color: var(
							--sl-secondary-text-icon-main
						);
						display: block;
						border-radius: 50%;
						transform: scale(1.2);
					}
				}
			}

			.p-checkbox {
				margin-right: 10px;
			}

			&.p-highlight {
				@include color(var(--sl-primary-text-icon-main));
				background: var(--sl-dark-background-main);
			}

			&:not(.p-highlight):not(.p-disabled):hover {
				background: var(--sl-dark-background-main);
			}

			&:hover {
				.hoverable {
					visibility: visible;
				}
			}
		}
	}
}

.p-dropdown:not(.p-disabled).p-focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: none;
}
