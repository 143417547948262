@mixin table-column($width, $selectors...) {
	@each $item in $selectors {
		.mat-column-#{$item} {
			width: $width;
			box-sizing: content-box;
			@content;
		}
	}
}

@mixin table-column-box($width, $selectors...) {
	@each $item in $selectors {
		.mat-column-#{$item} {
			width: $width;
			box-sizing: border-box;
			@content;
		}
	}
}
