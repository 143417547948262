@use 'common' as *;

p-selectbutton:has(> .sl-v1-select-button),
p-selectbutton:has(> .sl-v1-select-button-secondary) {
	display: flex;
}

.sl-v1-select-button.p-selectbutton {
	display: flex;

	&.p-buttonset {
		background-color: var(--sl-primary-brand-active);
		display: flex;
		padding: space(0.5);
		border-radius: 8px;
		gap: 4px;
		height: 32px;

		&.tsr {
			height: 26px
		}

		.p-button {
			@include color(var(--sl-primary-brand-400));
			background: none;
			border: none;
			border-radius: 6px !important;
			font-size: 12px;
			font-weight: 500;
			padding: 0 space();

			&.p-highlight {
				@include color(var(--sl-primary-brand-active));
				background-color: var(--sl-white-main);

				&:hover {
					background-color: var(--sl-primary-brand-200);
				}
			}

			&:not(.p-disabled):not(.p-highlight):hover {
				background: var(--sl-primary-brand-hover);
			}
		}
	}
}

.sl-v1-select-button-secondary.p-selectbutton {
	display: flex;

	&.p-buttonset {
		background-color: var(--sl-dark-background-400);
		display: flex;
		padding: space(0.5);
		border-radius: 8px;
		gap: 4px;
		height: 32px;
		outline: none;
		box-shadow: none;

		.p-button {
			@include color(var(--sl-disabled-mode-lines-500));
			outline: none;
			background: none;
			border: none;
			border-radius: 6px !important;
			font-size: 12px;
			font-weight: 500;
			padding: 0 space();
			box-shadow: none;

			&.p-highlight {
				@include color(var(--sl-primary-text-icon-500));
				background-color: var(--sl-white-main);
				box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

				&:hover {
					background-color: var(--sl-disabled-mode-lines-200);
				}
			}

			&:not(.p-disabled):not(.p-highlight):hover {
				background: var(--sl-disabled-mode-lines-200);
			}
		}
	}
}

.sl-v1-select-button-rounded.p-selectbutton {
	&.p-buttonset {
		background-color: var(--sl-white-main);
		@include color(var(--sl-primary-text-icon-200));
		display: flex;
		border-radius: 20px;
		height: 32px;
		outline: none;
		box-shadow: none;
		align-items: center;
		font-size: 14px;
		font-weight: 400;

		.p-button {
			@include color(var(--sl-primary-text-icon-200));
			height: 32px;
			outline: none;
			background: none;
			padding: 0 space(2);
			border: 1px solid var(--sl-primary-brand-500);
			font-weight: 400;
			box-shadow: none;
			font-size: 14px;

			&:first-child {
				border-radius: 20px 0 0 20px;
			}

			&:last-child {
				border-radius: 0 20px 20px 0;
			}

			&.p-highlight {
				background: var(--sl-primary-brand-300);

			}

			&:not(.p-disabled):not(.p-highlight):hover {
				background-color: var(--sl-primary-brand-200);
			}
		}
	}
}