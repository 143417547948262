@use 'common' as *;

.sl-body {
	.mat-mdc-select-panel.new-design {
		background-color: var(--sl-element-background-main);

		.mat-mdc-option {
			@include sl-typography-body;
			@include color(var(--sl-primary-text-icon-main));
			background-color: var(--sl-element-background-main);
			border-radius: space(0.5);
			height: space(4);
			margin: 0 space();

			&:first-of-type {
				margin-top: space();
			}

			&:last-of-type {
				margin-bottom: space();
			}

			&.mat-mdc-selected,
			&:hover {
				background-color: var(--sl-dark-background-main);
				background-image: none;
			}
		}
	}
}
