@import 'p-accordion';
@import 'p-calendar';
@import 'p-checkbox';
@import 'p-datatable';
@import 'p-dialog';
@import 'p-dropdown';
@import 'p-inline-message';
@import 'p-input-number';
@import 'p-input-switch';
@import 'p-menu';
@import 'p-progress-spinner';
@import 'p-radiobutton';
@import 'p-select-button';
@import 'p-sidebar';
@import 'p-table';
@import 'p-toast';
@import 'p-tooltip';

.sl-v1 {

	.p-disabled,
	&.p-disabled,
	&.p-calendar-disabled {
		opacity: 0.3;
	}

	.p-checkbox,
	.p-checkbox .p-checkbox-box {
		height: 14px;
		width: 15px;
	}
}
