@use 'common' as *;

@mixin sl-v1-field {
	border-radius: 6px;
	border: 1px solid var(--sl-dark-background-400);
	background: var(--sl-white-main);
	width: 100%;
}

@mixin sl-v1-input {
	@include color(var(--sl-primary-text-icon-main));
	font-size: 14px;
	padding: 9px 16px;
    line-height: 16px;
}

@mixin sl-v1-filter {
	font-size: 14px;
	border-radius: 5px;
	padding: 7px space();
	padding-left: 30px;
}

@mixin sl-v1-filter-icon {
	right: unset;
	left: 8px;
	height: 14px
}

@mixin sl-v1-dropdown-open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

@mixin sl-v1-dropdown-open-panel {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}