@use 'common' as *;

.sl-body {
	.mdc-data-table__header-cell {
		--mdc-typography-subtitle2-line-height: 14px;
	}

	/* Data Table (actually Material table but this section includes only custom class names) */

	table[mat-table] {
		border-spacing: 0;
		table-layout: fixed;
		width: 100%;

		tr[mat-header-row] {
			th[mat-header-cell] {
				@include color(var(--sl-black-main));
				border-bottom: 1px solid $sl-color-table-header-cell-border;
				font-size: 12px;
				font-weight: 400;
				overflow: hidden; // Making problem on bigger screens
				padding: 0;
				text-overflow: ellipsis;
				text-transform: uppercase;
				white-space: nowrap;
				width: auto;

				&.center {
					text-align: center;
	
					.mat-sort-header-container {
						justify-content: center;
					}
				}

				&.mat-column-select {
					/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
					.mat-checkbox-inner-container:hover {
						/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
						.mat-checkbox-persistent-ripple {
							opacity: 0;
						}
					}
				}

				.mat-sort-header-container {
					.mat-sort-header-button {
						font-size: 12px;
						text-transform: uppercase;
					}
				}
			}
		}

		tr[mat-row] {
			cursor: pointer;
			height: 48px;
			position: relative;

			&:hover {
				background-image: linear-gradient(to right, var(--sl-white-main), $color-background-gray-500);
			}

			&:hover,
			&.focused,
			&.expanded {
				@include linear-gradient-status(quality-status-grad-bg);
			}

			&:hover {
				> td[mat-cell] {
					> .floating-toolbar {
						display: block;
					}

					> .data-cell-content {
						> .floating-toolbar {
							display: block;
						}
					}

					.show-on-hover {
						visibility: visible;
					}
				}
			}

			td[mat-cell] {
				@include color(var(--sl-black-main));
				border-bottom: 1px solid $sl-color-table-data-cell-border;
				letter-spacing: normal;
				overflow: hidden; // Making problem on bigger screens
				padding: 0;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: auto;

				&:last-of-type {
					padding-right: space(3);
				}

				&.mat-column-select {
					/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
					.mat-checkbox-inner-container:hover {
						/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
						.mat-checkbox-persistent-ripple {
							opacity: 0;
						}
					}
				}

				&.with-floating-toolbar {
					overflow: visible;
					position: relative;
				}

				.match {
					background-color: $color-text-highlight;
				}

				.avatar-container {
					.avatar-content {
						@include color(var(--sl-white-main));
					}
				}

				.blank-avatar {
					.avatar-container {
						.avatar-content {
							@include color(var(--sl-black-main));
						}
					}
				}

				.show-on-hover {
					&:last-of-type {
						margin-right: 0;
					}

					cursor: pointer;
					margin-right: space();
					visibility: hidden;
				}
			}
		}
	}

	table[mat-table] {
		tr[mat-header-row] {
			th[mat-header-cell] {
				.sub-header {
					&[aria-sort] {
						.mat-sort-header-arrow {
							display: flex; /* Show the sorting icon if there's sorting */
						}
					}

					.mat-sort-header-content {
						background-color: var(--sl-white-main);
						@include color($color-text-600);
						font-size: 10px;
						height: 11px;
						letter-spacing: 0.42px;
						padding: 0 space(0.5);
						text-transform: none;
					}

					.mat-sort-header-arrow {
						background-color: var(--sl-white-main);
						display: none; /* Hide the sorting icon if there's no sorting. Used in order to center the text of the "sub-header". */
					}
				}
			}
		}
	}

	/* Nadav's work on TGA */

	table[mat-table] {
		.sub-cell {
			border-right: 1px solid rgba(0, 0, 0, 0.12);
			padding-bottom: 18px;
			padding-top: 21px;
			text-align: center;
			width: space(17);

			&.no-right-border {
				border-right: none;
			}
		}

		.mat-mdc-header-row {
			th {
				height: 41px;
			}

			.sub-header {
				text-align: center;
				width: 25%;

				.mat-sort-header-container {
					justify-content: center;
					margin-top: space(1.5);

					button {
						margin: 0 auto;
					}
				}
			}
		}
	}
}
