@use 'common' as *;
@use 'mixins' as *;

.p-calendar.sl-v1 {
	width: 100%;

	.p-inputtext {
		@include sl-v1-field;
		@include sl-v1-input;
		padding-right: space(4.5);
	}

	.p-datepicker .p-datepicker-header .p-datepicker-title {

		.p-datepicker-month,
		.p-datepicker-year {
			font-size: 14px;
			font-weight: 500;
			padding: 0;
		}
	}

	.p-datepicker-trigger {
		@include color(var(--sl-secondary-text-icon-300));
		position: absolute;
		right: 0;
		background: none;
		border: none;
		top: 1px;
	}

	.p-datepicker table {
		thead {
			border-bottom: 1px solid var(--sl-dark-background-400);
		}

		th {
			padding: space(2) 0;
			font-size: 12px;
			font-weight: normal;
			width: 32px;
		}

		tr {
			display: flex;
			padding: space() 0;
		}

		td {
			padding: 0;

			>span {
				@include color(var(--sl-secondary-text-icon-300));
				height: 24px;
				width: 32px;
				border-radius: 0;
				font-size: 14px;

				&.p-highlight {
					@include color(var(--sl-secondary-text-icon-300));
					background-color: var(--sl-primary-brand-200);
				}
			}
		}
	}
}

.p-inputwrapper:not(.p-inputwrapper-filled) {
	.p-calendar.sl-v1 { 
		.p-inputtext {
			@include color(var(--sl-secondary-text-icon-300));
		}
	}
}