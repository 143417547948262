@use 'common' as *;

.sl-body {
	mat-list,
	mat-action-list,
	mat-nav-list {
		[mat-list-item] {
			.match {
				background-color: $color-text-highlight;
			}
		}
	}

	.list {
		.list-item {
			.match {
				background-color: $color-text-highlight;
			}
		}
	}

	.mat-mdc-list-base {
		--mdc-list-list-item-one-line-container-height: 40px;
	}

	.mat-mdc-list-option {
		--mdc-checkbox-selected-checkmark-color: var(--sl-white-main);
		--mdc-checkbox-selected-icon-color: var(--sl-black-main);
		--mdc-checkbox-selected-focus-icon-color: var(--sl-black-main);
		--mdc-checkbox-selected-hover-icon-color: var(--sl-black-main);
		--mdc-checkbox-selected-pressed-icon-color: var(--sl-black-main);
		--mdc-checkbox-state-layer-size: 0;
		--mdc-checkbox-unselected-focus-state-layer-opacity: 0;

		.mdc-checkbox__background {
			@include square(16px);
		}
	}

	.mdc-list-item {
		padding-left: 0;
		padding-right: 0;
	}

	.mdc-list-item__primary-text {
		--mdc-list-list-item-label-text-size: 14px;
		--mdc-list-list-item-label-text-tracking: normal;
	}

	.mdc-list-item--with-leading-checkbox {
		&.mdc-list-item--with-one-line {
			height: space(5);
		}

		.mat-mdc-list-option-checkbox-before {
			margin: 0;
		}

		.mdc-list-item__content {
			padding-left: space();
		}
	}
}
