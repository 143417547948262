@use 'variables' as *;
@use 'new-variables' as *;

/***********/
/* General */
/***********/

@function space($units: 1) {
	@return $units * $spacing-unit;
}

@mixin square($dimension: space()) {
	height: $dimension;
	width: $dimension;
}

@mixin icon($dimension) {
	height: $dimension;
	width: $dimension;
	font-size: $dimension;
	flex: 0 0 $dimension;
}

@mixin circle($dimension: space()) {
	height: $dimension;
	width: $dimension;
	border-radius: 50%;
}

@mixin color($color) {
	color: $color;
	-webkit-text-fill-color: $color;
}

@mixin animation($animation) {
	animation: $animation;
	-webkit-animation: $animation;
}

@mixin linear-gradient($direction, $from, $to) {
	background-image: linear-gradient(to $direction, $from, $to);
}

@mixin linear-gradient-awaiting($direction) {
	@include linear-gradient($direction, var(--sl-white-main), rgba(36, 52, 80, 0.2));
}

@mixin linear-gradient-incomplete($direction) {
	@include linear-gradient($direction, var(--sl-white-main), rgba(155, 155, 155, 0.2));
}

@mixin linear-gradient-failed($direction) {
	@include linear-gradient($direction, var(--sl-white-main), rgba(241, 41, 81, 0.2));
}

@mixin linear-gradient-passed($direction) {
	@include linear-gradient($direction, var(--sl-white-main), rgba(21, 175, 155, 0.2));
}

@mixin linear-gradient-status($status, $direction: right) {
	&.#{$status} {
		&.quality-status-awaiting-grad-bg {
			@include linear-gradient-awaiting($direction);
		}

		&.quality-status-incomplete-grad-bg {
			@include linear-gradient-incomplete($direction);
		}

		&.quality-status-failed-grad-bg {
			@include linear-gradient-failed($direction);
		}

		&.quality-status-passed-grad-bg {
			@include linear-gradient-passed($direction);
		}
	}
}

@mixin box-shadow($offsetX, $offsetY, $blurRadius, $spreadRadius, $color) {
	box-shadow: $offsetX $offsetY $blurRadius $spreadRadius $color;
}

@mixin dividing-border($side: right, $width: 1px, $type: solid, $color: $color-divider-400) {
	border-#{$side}: $width $type $color;
}

/*****************/
/* More specific */
/*****************/

@mixin json-table-cell {
	padding-right: space();
	text-align: center;
	width: space(6);
}

@mixin ellipsis {
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

@mixin sl-box-border($top: true, $right: true, $bottom: true, $left: true) {
	@if $top {
		border-top: 0.5px solid var(--sl-disabled-mode-lines-main);
	}
	@if $right {
		border-right: 0.5px solid var(--sl-disabled-mode-lines-main);
	}
	@if $bottom {
		border-bottom: 0.5px solid var(--sl-disabled-mode-lines-main);
	}
	@if $left {
		border-left: 0.5px solid var(--sl-disabled-mode-lines-main);
	}
}

@mixin sl-box-border-x {
	@include sl-box-border(false, true, false, true);
}

@mixin sl-box-border-y {
	@include sl-box-border(true, false, true, false);
}

/** Make Scroll fancy **/
@mixin sl-fancy-scroll {
	/* width */
	&::-webkit-scrollbar {
		width: $sl-scrollbar-width;
		height: $sl-scrollbar-width;
		scroll-behavior: smooth;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background-color: var(--sl-secondary-text-icon-main);
		border-radius: 5px;
		background-clip: padding-box;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: $color-tree-connector;
	}
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	z-index: 1;
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin ellipsis-reverse {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	direction: rtl;
}

// new border color

@mixin sl-table-border($top: true, $right: true, $bottom: true, $left: true) {
	@include sl-border($top, $right, $bottom, $left);
}

@mixin sl-border($top: true, $right: true, $bottom: true, $left: true, $color: var(--sl-background-200)) {
	@if $top {
		border-top: 1px solid $color;
	}
	@if $right {
		border-right: 1px solid $color;
	}
	@if $bottom {
		border-bottom: 1px solid $color;
	}
	@if $left {
		border-left: 1px solid $color;
	}
}

@mixin screen-xxl {
	@media screen and (min-width: $sl-screen-xxl) {
		@content;
	}
}

@mixin screen-xl {
	@media screen and (min-width: $sl-screen-xl) {
		@content;
	}
}

@mixin screen-lg {
	@media screen and (min-width: $sl-screen-lg) {
		@content;
	}
}

@mixin screen-md {
	@media screen and (max-width: $sl-screen-lg) {
		@content;
	}
}

@mixin screen-sm {
	@media screen and (max-width: $sl-screen-md) {
		@content;
	}
}

@mixin screen-xs {
	@media screen and (max-width: $sl-screen-sm) {
		@content;
	}
}

@mixin button-base {
	color: white;
	border-radius: 4px;
	height: 36px;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.42px;
	border: none;
	box-shadow: none;
	display: inline-flex;
	align-items: center;
	gap: 4px;
	padding: 4px 24px;
	cursor: pointer;

	.sl-button-icon {
		height: 20px;
		width: 20px;
		font-size: 20px;
	}

	.sl-button-label {
		white-space: nowrap;
	}

	&:disabled {
		cursor: default;
	}
}
