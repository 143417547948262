@use 'common' as *;

.sl-body {
	.filter-panel-tia.p-overlaypanel {
		background-color: var(--sl-white-main);
		padding: 24px;
		border-radius: 5px;

		.p-overlaypanel-content {
			margin: 0;
			padding: 0;
		}

		.mat-mdc-radio-button {
			/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
			.mat-radio-container {
				@include square(14px);
				/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
				.mat-radio-outer-circle {
					@include square(14px);
				}
				/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
				.mat-radio-inner-circle {
					@include square(14px);
				}
				/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
				.mat-ripple.mat-radio-ripple {
					display: none;
				}
			}
			/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
			.mat-radio-label-content {
				margin-left: space(1.25);
				padding-left: 0;
			}
		}
	}
}
