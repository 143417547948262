@use 'common' as *;

$sl-tooltip-padding: space();
$sl-tooltip-font-size: 14px;

.sl-body {
	.mat-mdc-tooltip {
		@include color(var(--sl-primary-text-icon-300));
		@include sl-box-border;
		border-radius: 5px;
		box-shadow: 0px 4px 8px -2px rgba(57, 60, 67, 0.16);
		display: inline-block;
		background-color: $color-tooltip-background;
		font-size: $sl-tooltip-font-size;
		padding: $sl-tooltip-padding;
		margin: space(0.5) space(1);
		max-width: 100%;
		white-space: pre-line;
		word-break: break-word;
		align-items: flex-start;
		text-align: left;

		&.inline {
			white-space: nowrap;

			.mdc-tooltip__surface {
				max-width: 100%;
			}
		}

		&.padding-lg {
			padding: calc(#{$sl-tooltip-padding} * 2);
		}

		&.short-width {
			width: 500px;
		}

		&.pre-line {
			white-space: pre-line;
			max-width: unset;
			line-height: calc(#{$sl-tooltip-font-size} + #{$sl-tooltip-padding});
		}

		&.right-edge {
			margin-left: -100%;
			margin-right: 100%;
		}

		.mdc-tooltip__surface {
			@include color(inherit);
			align-items: flex-start;
			background-color: inherit;
			font-size: inherit;
			letter-spacing: inherit;
			text-align: left;
		}
	}

	/* sl-tooltip */

	.cdk-overlay-container:has(.mat-mdc-tooltip-panel) {
		z-index: 2000;

		.tia-branch-list-tooltip {
			@include color(var(--sl-primary-text-icon-main));
			background-color: var(--sl-white-main);
			border: 1px solid $sl-border-color;
			border-radius: 5px;
			box-shadow: 0 2px 10px $sl-border-color;
			max-height: 200px;
			padding: space();

			.branch-name {
				font-size: 14px;

				&:not(:last-of-type) {
					padding-bottom: space();
				}

				&.more {
					@include color(var(--sl-secondary-text-icon-main));
				}
			}
		}
	}
}
