@use 'common' as *;

.sl-body {
	.mat-mdc-form-field.new-design {
		width: 100%;

		&.mat-form-field-appearance-outline {
			&.mat-focused {
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-wrapper {
					/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
					.mat-form-field-flex {
						/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
						.mat-form-field-outline {
							opacity: 1;
							/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
							&.mat-form-field-outline-thick {
								opacity: 0;
							}
						}
					}
				}
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-wrapper {
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-flex {
					align-items: center;
					height: space(5);

					&:hover {
						/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
						.mat-form-field-outline {
							opacity: 1;
							/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
							&.mat-form-field-outline-thick {
								opacity: 0;
							}
						}
					}
					/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
					.mat-form-field-outline {
						background-color: var(--sl-element-background-main);

						/* .mat-form-field-outline-start,
						.mat-form-field-outline-gap,
						.mat-form-field-outline-end {
							border: 1px solid var(--sl-disabled-mode-lines-main);
						} */
					}
				}
			}
		}
	}
}
