@use 'common' as *;
.p-checkbox {
	@include square(14px);

	&:not(.p-checkbox-disabled) {
		cursor: pointer;
	}

	.p-checkbox-box {
		@include square(14px);

		border-color: var(--sl-disabled-mode-lines-400) !important;
		background: var(--sl-element-background-main) !important;

		&:not(.p-disabled) {
			cursor: pointer;
		}

		&.p-highlight {
			border-color: var(--sl-primary-brand-active) !important;
			background: var(--sl-primary-brand-active) !important;
		}

		.p-icon-wrapper {
			@include square(10px);
			justify-content: center;
			align-items: center;
		}
	}

}
	
.p-checkbox-label {
	margin-left: 16px;
	@include color(var(--sl-primary-text-icon-300));

	&:not(.p-disabled) {
		cursor: pointer;
	}

	&.p-disabled {
		opacity: 1;
	}
}