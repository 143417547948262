/* CSS-GRID */
@use './grid-mixins.scss' as *;

.sl-body {
	.sl-grid {
		@include sl-grid;
	}

	.sl-grid-columns {
		@include sl-grid-columns;
	}

	.sl-grid-rows {
		@include sl-grid-rows;
	}

	.sl-grid-auto-flow-column {
		@include sl-grid-auto-flow-column;
	}

	.sl-grid-auto-flow-row {
		@include sl-grid-auto-flow-row;
	}

	.sl-align-items-vertical-start {
		@include sl-align-items-vertical-start;
	}

	.sl-align-items-vertical-center {
		@include sl-align-items-vertical-center;
	}

	.center-by-grid {
		@include center-by-grid;
	}
}
