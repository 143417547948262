@use 'common' as *;

.sl-body {
	.mat-mdc-select {
		--mat-select-trigger-text-size: 14px;

		&.mat-mdc-select-disabled {
			.mat-mdc-select-trigger {
				.mat-mdc-select-value {
					@include color(var(--sl-secondary-text-icon-main));
				}

				.mat-mdc-select-arrow-wrapper {
					.mat-mdc-select-arrow {
						@include color(var(--sl-secondary-text-icon-main));
					}
				}
			}
		}
	}

	.mat-mdc-select-panel {
		&.mdc-menu-surface {
			@include sl-fancy-scroll();
			padding: 0;
		}

		&.mat-primary {
			.mat-pseudo-checkbox-checked {
				&.mat-pseudo-checkbox-minimal {
					&::after {
						@include color(var(--sl-primary-text-icon-main));
					}
				}
			}
		}

		.mat-mdc-option {
			@include color(var(--sl-primary-text-icon-main));
			background-color: var(--sl-white-main);
			font-size: 14px;
			height: 44px;
			padding: 0 space(2);

			&:hover {
				background-image: linear-gradient(to right, var(--sl-white-main), $color-background-gray-500);
			}

			&.mat-mdc-selected {
				&:not(.mat-mdc-option-multiple) {
					background-color: $color-background-gray-500;
				}
				/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
				&:not(.mat-option-disabled) {
					@include color(var(--sl-primary-text-icon-main));
				}

				.mat-mdc-option-pseudo-checkbox {
					background-color: var(--sl-black-main);
				}
			}

			.mat-mdc-option-pseudo-checkbox {
				@include color(transparent);
				background-color: transparent;
				border: none;
				font-size: 14px;

				&.mat-pseudo-checkbox-checked {
					@include color(var(--sl-primary-text-icon-main));
				}

				&::after {
					@include color(inherit);
				}
			}
		}
	}
}
