.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
		0 8px 10px 1px rgba(0, 0, 0, 0.14),
		0 3px 14px 2px rgba(0, 0, 0, 0.12);
	z-index: 1200 !important;
	background-color: white;
}

.cdk-drag-placeholder {
	opacity: 0;
}

.cdk-drag-animating {
	transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-item:last-child {
	border: none;
}

.cdk-drop-list-dragging .drag-drop-item:not(.cdk-drag-placeholder) {
	transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}
