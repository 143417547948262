@use 'common' as *;

.sl-body {
	p-multiselect.ng-dirty.ng-invalid {
		>.p-multiselect {
			border-color: var(--sl-disabled-mode-lines-main);
		}
	}

	.p-multiselect.new-design {
		background-color: var(--sl-element-background-main);
		border-color: var(--sl-disabled-mode-lines-main);
		border-radius: space(0.5);
		height: space(5);
		padding: 0 10px 0 space(2);
		width: 100%;

		&:hover {
			border-color: var(--sl-disabled-mode-lines-main);
		}

		&.p-focus {
			box-shadow: none;
			border-color: var(--sl-primary-text-icon-main);
		}

		&.p-multiselect-open {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			.p-multiselect-trigger {
				.p-multiselect-trigger-icon {
					&::before {
						content: '\e903';
						font-size: 12px;
					}
				}
			}
		}

		.p-multiselect-trigger-icon {
			&::before {
				font-size: 12px;
			}
		}

		.p-multiselect-label-container {
			@include sl-typography-body;
			display: inline-flex;
			align-items: center;

			.p-multiselect-label {
				padding: 0 space(1.5) 0 0;
			}
		}

		.p-multiselect-trigger {
			@include color(var(--sl-primary-text-icon-main));
			align-self: center;
			width: auto;

			.p-multiselect-trigger-icon.p-icon {
				@include square(12px);
			}
		}
	}

	.p-multiselect-panel.new-design {
		background-color: var(--sl-element-background-main);
		border-radius: 0 0 $sl-border-radius $sl-border-radius;
		box-shadow: $sl-box-shadow;
		z-index: 1101 !important;

		&.part-selected {
			.p-multiselect-header {
				.p-checkbox {
					.p-checkbox-box {
						background-color: var(--sl-primary-text-icon-main);
						border-color: var(--sl-primary-text-icon-main);

						.p-checkbox-icon {
							&::before {
								content: '\2014';
							}
						}
					}
				}
			}
		}

		.p-multiselect-header {
			padding: space() space() space() space(2);
			background-color: var(--sl-element-background-main);
			border-bottom: 1px solid var(--sl-disabled-mode-lines-main);

			.p-checkbox {
				@include square(14px);
				border-radius: 2px;
				margin-right: space(2);

				.p-checkbox-box {
					@include square(14px);
					border-radius: 2px;

					&.p-focus {
						outline: none;
						box-shadow: none;
						border-color: var(--sl-primary-text-icon-main);
					}

					&.p-highlight {
						background-color: var(--sl-primary-text-icon-main);
						border-color: var(--sl-primary-text-icon-main);
					}

					.p-checkbox-icon {
						@include color(var(--sl-white-main));
						background-color: var(--sl-primary-text-icon-main);
						font-size: 10px;

						&::before {
							font-weight: 600;
						}
					}
				}
			}

			.p-checkbox.checkbox-item {
				margin-right: space(1.5);
			}

			.p-checkbox-label.checkbox-item {
				margin-left: 0;
			}

			.p-multiselect-close {
				display: none;
			}

			.p-multiselect-filter-icon {
				display: none;
			}
		}

		.p-multiselect-filter {
			@include sl-typography-body;
			@include color(var(--sl-primary-text-icon-main));
			height: space(4);
			border-radius: $sl-border-radius;
			padding: space();

			&::placeholder {
				@include color(var(--sl-secondary-text-icon-main));
			}
		}

		.dropdown-footer {
			@extend .p-footer;
		}

		.p-multiselect-items-wrapper {
			@include sl-fancy-scroll;
			@include sl-typography-body;

			.p-multiselect-items {
				@include sl-typography-body;
				padding: space() 0;

				&:focus {
					box-shadow: none;
				}

				&.p-highlight {
					@include color(var(--sl-primary-text-icon-main));
					background-color: var(--sl-element-background-main);
				}

				.p-multiselect-item {
					border-radius: $sl-border-radius;
					height: space(4);
					margin: 0 space();
					padding: space();

					&.p-highlight {
						@include color(var(--sl-primary-text-icon-main));
						background-color: var(--sl-element-background-main);
					}

					&:hover {
						background-color: var(--sl-dark-background-main);
					}

					&:focus {
						box-shadow: none;
					}

					.p-checkbox {
						align-items: center;

						.p-checkbox-box {
							@include square(14px);

							&.p-highlight {
								background-color: var(--sl-primary-text-icon-main);
								border-color: var(--sl-primary-text-icon-main);
							}

							.p-checkbox-icon {
								@include color(var(--sl-white-main));
								background-color: var(--sl-primary-text-icon-main);
								font-size: 10px;

								&::before {
									font-weight: 600;
								}
							}
						}
					}

					// This is used in trend report - Quality analytics
					.build-item {
						.build-name {
							margin-right: space();
							line-height: space(2);
						}
					}
				}
			}
		}

		&.sticky-group-header {
			.p-multiselect-item-group {
				position: sticky;
				top: 0;
				z-index: 99999;
			}
		}

		.tr-action-button {
			visibility: hidden;
		}

		.tr-dropdown-item {
			width: 100%;

			&:hover {
				.tr-action-button {
					visibility: visible;
				}
			}
		}
	}

	.p-multiselect-panel.new-design.item-lg {
		.p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item {
			height: 40px;
		}
	}

	.p-multiselect-panel.new-design.big-row {
		.p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item {
			height: 48px;
		}
	}

	.p-multiselect-panel.new-design.column-header {
		.p-multiselect-header {
			display: flex;
			flex-direction: column;
		}
	}

	.p-multiselect.new-design-inline {
		border: none;
		width: 100%;

		&:hover {
			border-color: var(--sl-disabled-mode-lines-main);
		}

		&.p-focus {
			box-shadow: none;
		}

		&.p-disabled {
			.p-multiselect-label {
				@include color(var(--sl-primary-text-icon-main));
			}
		}

		.p-multiselect-label {
			@include color(var(--sl-primary-brand-pressed));
			text-decoration-line: underline;
			font-size: 12px;
		}

		&.p-multiselect-open {
			.p-multiselect-trigger {
				.p-multiselect-trigger-icon {
					&::before {
						content: '\e903';
					}
				}
			}
		}

		.p-multiselect-label-container {
			@include sl-typography-body;
			display: inline-flex;
			align-items: center;
		}

		.p-multiselect-trigger {
			align-self: center;
			width: space();

			.p-multiselect-trigger-icon {
				&::before {
					font-size: 10px;
				}

				font-size: 10px;
			}
		}
	}

	.p-multiselect-panel.new-design.custom-group-item {

		.p-multiselect-items-wrapper {
			padding: space();
		}
		.p-multiselect-items {
			padding: 0;

			.p-multiselect-item-group  {
				padding: 0;
			}
		}

		.p-multiselect-footer {
			padding: space();
		}
	}
}