// External imports
@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900&subset=latin-ext');

// Custom app theme
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

// Abstracts
@import './sass/index.scss';

// CDK
@import '@angular/cdk/overlay-prebuilt.css';
