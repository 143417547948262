@use 'common' as *;

.sl-body {
	p-inputswitch {
		.p-inputswitch {
			&.new-design {
				height: 26px;

				&.p-focus {
					.p-inputswitch-slider {
						box-shadow: none;
					}
				}

				&.p-inputswitch-checked {
					.p-inputswitch-slider {
						background-color: var(--sl-primary-brand-active);

						&::after {
							content: 'On';
							left: 6px;
							top: 6px;
						}
					}
				}

				.p-inputswitch-slider {
					&::before {
						background-color: var(--sl-white-main);
					}

					&::after {
						@include color(var(--sl-white-main));
						content: 'Off';
						font-size: 10px;
						position: absolute;
						right: 6px;
						top: 6px;
					}
				}
			}
		}
	}
}
