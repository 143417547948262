.lab-mapping-pick-list {
    .p-picklist-buttons button {
        background-color: var(--sl-white-main);
        @include color(var(--sl-disabled-mode-lines-main));
        border: 0px;

        &:nth-child(even) {
            display: none;
        }

        &:hover {
            background-color: var(--sl-element-background-300);
        }

        &:focus {
            box-shadow: none;
        }
    }

    .p-picklist-header {
        background-color: var(--sl-white-main);
        border: 0px;
    }

    .p-picklist-filter-container,
    .cdk-drop-list {
        border: 0px;
    }

    .p-picklist-item {
        padding: 0px;

        &.p-highlight {
            color: unset;
            background-color: var(--sl-dark-background-300);
        }

        &:focus {
            box-shadow: none;
        }

        &:hover {
            background-color: var(--sl-dark-background-100);
        }
    }

    li {
        &:has(sl-loading) {
            pointer-events: none;
        }
    }
}
