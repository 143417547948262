@use 'common' as *;

.sl-v1-dialog {

	.mat-mdc-dialog-surface {
		overflow: visible;
	}

	.mat-mdc-dialog-container {
		.mdc-dialog__surface {
			border-radius: 8px;
		}

		.mat-mdc-dialog-title {
			display: flex;
			gap: 16px;
			padding: space(3);
			padding-bottom: 0;
			line-height: 1;

			&::before {
				display: none;
			}

			.dialog-title {
				line-height: 1;
				margin: 0;
				font-size: 24px;
				letter-spacing: -0.48px;
			}

			.button-close {
				right: 12px;
				top: 12px;
				position: absolute;
			}
		}

		.mat-mdc-dialog-content {
			@include color(var(--sl-black-main));
			margin: 0;
			margin-top: space(3);
			font-size: 14px;
			letter-spacing: normal;
			overflow: hidden;
			max-height: unset;
			line-height: unset;

			p {
				padding: 0;
				margin: 0;
			}

			&.sl-overflow-y-auto {
				overflow: auto;
			}
		}

		.mat-mdc-dialog-actions {
			margin: 0;
			padding: 0 space(3) space(3) space(3);
			justify-content: end;
			gap: 16px;
		}
	}

}
