@use 'common' as *;

.sl-body {
	.mat-mdc-progress-spinner {
		margin: 0 auto;

		circle {
			stroke: var(--sl-primary-brand-active);
		}
	}
}
