.sl-body {

	.sl-primary-brand-100 {
		@include color(var(--sl-primary-brand-100));
	}

	.sl-primary-brand-200 {
		@include color(var(--sl-primary-brand-200));
	}

	.sl-primary-brand-300 {
		@include color(var(--sl-primary-brand-300));
	}

	.sl-primary-brand-400 {
		@include color(var(--sl-primary-brand-400));
	}

	.sl-primary-brand-500 {
		@include color(var(--sl-primary-brand-500));
	}

	.sl-primary-brand-active {
		@include color(var(--sl-primary-brand-active));
	}

	.sl-primary-brand-focus {
		@include color(var(--sl-primary-brand-focus));
	}

	.sl-primary-brand-hover {
		@include color(var(--sl-primary-brand-hover));
	}

	.sl-primary-brand-pressed {
		@include color(var(--sl-primary-brand-pressed));
	}

	.sl-secondary-brand-yellow-main {
		@include color(var(--sl-secondary-brand-yellow-main));
	}

	.sl-secondary-brand-yellow-100 {
		@include color(var(--sl-secondary-brand-yellow-100));
	}

	.sl-secondary-brand-yellow-200 {
		@include color(var(--sl-secondary-brand-yellow-200));
	}

	.sl-secondary-brand-yellow-300 {
		@include color(var(--sl-secondary-brand-yellow-300));
	}

	.sl-secondary-brand-yellow-400 {
		@include color(var(--sl-secondary-brand-yellow-400));
	}

	.sl-secondary-brand-yellow-500 {
		@include color(var(--sl-secondary-brand-yellow-500));
	}

	.sl-background-main {
		background-color: var(--sl-background-main);
	}

	.sl-background-white {
		background-color: var(--sl-white-main);
	}

	.sl-background-100 {
		background-color: var(--sl-background-100);
	}

	.sl-background-200 {
		background-color: var(--sl-background-200);
	}

	.sl-background-300 {
		background-color: var(--sl-background-300);
	}

	.sl-background-400 {
		background-color: var(--sl-background-400);
	}

	.sl-background-500 {
		background-color: var(--sl-background-500);
	}

	.sl-dark-background-main {
		@include color(var(--sl-dark-background-main));
	}

	// Change all of them to look like this:
	.sl-dark-background-100 {
		background-color: var(--sl-dark-background-100);
	}

	.sl-dark-background-200 {
		@include color(var(--sl-dark-background-200));
	}

	.sl-dark-background-300 {
		@include color(var(--sl-dark-background-300));
	}

	.sl-dark-background-400 {
		@include color(var(--sl-dark-background-400));
	}

	.sl-dark-background-500 {
		@include color(var(--sl-dark-background-500));
	}

	.sl-disabled-mode-lines-main {
		@include color(var(--sl-disabled-mode-lines-main));
	}

	.sl-disabled-mode-lines-100 {
		@include color(var(--sl-disabled-mode-lines-100));
	}

	.sl-disabled-mode-lines-200 {
		@include color(var(--sl-disabled-mode-lines-200));
	}

	.sl-disabled-mode-lines-300 {
		@include color(var(--sl-disabled-mode-lines-300));
	}

	.sl-disabled-mode-lines-400 {
		@include color(var(--sl-disabled-mode-lines-400));
	}

	.sl-disabled-mode-lines-500 {
		@include color(var(--sl-disabled-mode-lines-500));
	}

	.sl-element-background-main {
		@include color(var(--sl-element-background-main));
	}

	.sl-element-background-100 {
		@include color(var(--sl-element-background-100));
	}

	.sl-element-background-200 {
		@include color(var(--sl-element-background-200));
	}

	.sl-element-background-300 {
		@include color(var(--sl-element-background-300));
	}

	.sl-element-background-400 {
		@include color(var(--sl-element-background-400));
	}

	.sl-white-main {
		@include color(var(--sl-white-main));
	}

	.sl-black-main {
		@include color(var(--sl-black-main));
	}

	.sl-primary-text-icon-main {
		@include color(var(--sl-primary-text-icon-main));
	}

	.sl-primary-text-icon-100 {
		@include color(var(--sl-primary-text-icon-100));
	}

	.sl-primary-text-icon-200 {
		@include color(var(--sl-primary-text-icon-200));
	}

	.sl-primary-text-icon-300 {
		@include color(var(--sl-primary-text-icon-300));
	}

	.sl-primary-text-icon-400 {
		@include color(var(--sl-primary-text-icon-400));
	}

	.sl-primary-text-icon-500 {
		@include color(var(--sl-primary-text-icon-500));
	}

	.sl-secondary-text-icon-main {
		@include color(var(--sl-secondary-text-icon-main));
	}

	.sl-secondary-text-icon-100 {
		@include color(var(--sl-secondary-text-icon-100));
	}

	.sl-secondary-text-icon-200 {
		@include color(var(--sl-secondary-text-icon-200));
	}

	.sl-secondary-text-icon-300 {
		@include color(var(--sl-secondary-text-icon-300));
	}

	.sl-secondary-text-icon-400 {
		@include color(var(--sl-secondary-text-icon-400));
	}

	.sl-alert-error-active {
		@include color(var(--sl-alert-error-active));
	}

	.sl-alert-error-disabled {
		@include color(var(--sl-alert-error-disabled));
	}

	.sl-alert-error-100 {
		@include color(var(--sl-alert-error-100));
	}

	.sl-alert-error-200 {
		@include color(var(--sl-alert-error-200));
	}

	.sl-alert-error-300 {
		@include color(var(--sl-alert-error-300));
	}

	.sl-alert-error-400 {
		@include color(var(--sl-alert-error-400));
	}

	.sl-alert-error-500 {
		@include color(var(--sl-alert-error-500));
	}

	.sl-alert-success-active {
		@include color(var(--sl-alert-success-active));
	}

	.sl-alert-success-disabled {
		@include color(var(--sl-alert-success-disabled));
	}

	.sl-alert-success-100 {
		@include color(var(--sl-alert-success-100));
	}

	.sl-alert-success-200 {
		@include color(var(--sl-alert-success-200));
	}

	.sl-alert-success-300 {
		@include color(var(--sl-alert-success-300));
	}

	.sl-alert-success-400 {
		@include color(var(--sl-alert-success-400));
	}

	.sl-charts-teal {
		@include color(var(--sl-charts-teal));
	}

	.sl-charts-light-teal {
		@include color(var(--sl-charts-light-teal));
	}

	.sl-charts-pink {
		@include color(var(--sl-charts-pink));
	}

	.sl-charts-light-pink {
		@include color(var(--sl-charts-light-pink));
	}

	.sl-charts-brown {
		@include color(var(--sl-charts-brown));
	}

	.sl-charts-light-brown {
		@include color(var(--sl-charts-light-brown));
	}

	.sl-charts-pale-blue {
		@include color(var(--sl-charts-pale-blue));
	}

	.sl-charts-light-pale-blue {
		@include color(var(--sl-charts-light-pale-blue));
	}

	.sl-charts-positive-green {
		@include color(var(--sl-charts-positive-green));
	}

	.sl-charts-hyper-links {
		@include color(var(--sl-charts-hyper-links));
	}

	.sl-charts-loader-blink {
		@include color(var(--sl-charts-loader-blink));
	}

	.sl-charts-recording-red {
		@include color(var(--sl-charts-recording-red));
	}

	.sl-charts-chip-1 {
		@include color(var(--sl-charts-chip-1));
	}

	.sl-charts-chip-2 {
		@include color(var(--sl-charts-chip-2));
	}
}
