/**
* --------------------------
* Global variables sass file
*
* 1.  Colors
* 2.  Z - Indexes
* 3.  Font-sizes
* 4.  Font-weights
* 5.  Shadows
***/

/***** colors *****/

$color-gray-lighter: #f1f3f4;
$color-gray: #dadcdf;
$color-grey-light: #6d6d6d;
$color-grey-medium: #d8d8d8;
$color-red-failed: #ff503f;
$color-yellow: #ffed00;
$color-light-green: #01d08a;
$color-green: #009f81;

$color-success: #15af9b;
$color-failure: #fe1443;
$color-warning: #ffc002;
$color-information: #169ace;
$color-error: $color-failure;
$color-error-bg: #feedee;

$color-text-200: #9b9b9b;
$color-text-400: #6d6d6d;
$color-text-600: #4a4a4a;
$color-text-highlight: #ffffaa;

$color-divider-400: #d7d7d7;
$color-divider-800: #011c2f;

$color-background-gray-200: #fafafa;
$color-background-gray-300: #f5f5f5;
$color-background-gray-500: #f1f3f4;
$color-background-gray-600: #f0f2f2;
$color-background-gray-700: #eaeaea;
$color-background-gray-900: #e4e4e4;

$color-primary-button: #f7e602;
$color-disabled-button: #dadcdf;

$color-link: #26b3f0;

$color-tooltip-background: #ffffff;

$color-quality-status-awaiting: #102243;
$color-quality-status-incomplete: $color-text-200;
$color-quality-status-failed: $color-failure;
$color-quality-status-passed: $color-success;
$color-quality-status-not-run: $color-text-200;

$color-high-priority: #ff5061;
$color-integration-build-background: #85d9e5;

$color-snackbar-error-background: #d53c45;

$color-tree-connector: #666666;

$color-editing-background: rgba(22, 154, 206, 0.1);

/**** Font-families ******/

$font-family: Roboto;

/**** z-indexes ******/

$z-index-loader-spinner: 110;
$z-index-dialog: 200;

/**** dimensions and spacing ******/

$spacing-unit: 8px;

/**** images and icons ******/

$image-login-page: url('/assets/images/login-page.png');
$image-logo-small: url('/assets/images/logo.svg');
$image-tia-no-build-changes: url('/assets/images/tia-no-build-changes.svg');
$image-tia-server-error: url('/assets/images/tia-server-issue.svg');

$icon-notifications: url('/assets/images/notification-icon-big.svg');
$icon-integration: url('/assets/images/integration-icon.svg');
$icon-datepicker-page: url('/assets/images/datepicker-page-icon.svg');
$icon-no-as-line: url('/assets/images/no-as-line-icon.svg');

$icon-scheduled: url('/assets/images/scheduled.svg');
$icon-unscheduled: url('/assets/images/unscheduled.svg');
$icon-firstrun: url('/assets/images/firstrun.svg');
$icon-yellow-exclamark: url('/assets/images/yellow-exclamark.svg');
$icon-test-stages-banner: url('/assets/images/test-stages-banner-icon.svg');
$icon-dashboard-search-no-results: url('/assets/images/dashboard-search-no-results.svg');
