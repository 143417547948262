@use 'common' as *;

.sl-body {
	.mat-datepicker-popup {
		.mat-calendar-header {
			.mat-calendar-controls {
				@include color(var(--sl-primary-text-icon-main));
				margin: 0 0 space() 0;

				.mat-calendar-previous-button,
				.mat-calendar-next-button {
					@include color(var(--sl-primary-text-icon-main));
					/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
					&.mat-mdc-button-disabled {
						@include color(var(--sl-secondary-text-icon-main));
					}
				}
			}
		}

		.mat-datepicker-content {
			background-color: var(--sl-element-background-main);

			mat-calendar.mat-calendar {
				height: auto;

				.mat-calendar-content {
					.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
						border-color: var(--sl-disabled-mode-lines-main);
					}

					.mat-calendar-table-header {
						@include color(var(--sl-secondary-text-icon-main));
					}

					.mat-calendar-body-label {
						@include color(var(--sl-secondary-text-icon-main));
					}

					mat-month-view {
						.mat-calendar-table {
							.mat-calendar-body {
								tr {
									.mat-calendar-body-cell {
										&.mat-calendar-body-in-range {
											&::before {
												background-color: var(--sl-dark-background-main);
											}
										}

										.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
											border-color: var(--sl-disabled-mode-lines-main);
										}

										.mat-calendar-body-selected {
											background-color: var(--sl-primary-brand-active);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
