@use 'common' as *;

:root {
	--mdc-text-button-label-text-weight: 400;
}

.sl-body {
	.mat-mdc-button {
		--mat-mdc-button-persistent-ripple-color: var(--sl-white-main);
		letter-spacing: normal;
	}

	.mat-mdc-button.new-design {
		@include sl-typography-button;
		background-color: var(--sl-primary-brand-active);

		border-radius: 5px;
		height: space(5);
		min-width: space(10);
		padding: 0 space(2);

		&.original-case {
			text-transform: none;
		}

		&.secondary {
			@include color(var(--sl-primary-brand-hover));
			background-color: var(--sl-white-main);
			border: 1px solid var(--sl-primary-brand-hover);

			&:disabled,
			&.mat-mdc-button-disabled {
				@include color(var(--sl-primary-brand-hover));

				background-color: var(--sl-white-main);
				opacity: 0.5;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
		&:hover:not(.mat-mdc-button-disabled) {
			background-color: var(--sl-primary-brand-hover);

			&.secondary {
				@include color(var(--sl-white-main));
			}
		}

		&:focus {
			background-color: $sl-color-bg-primary-light;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
		&:disabled,
		&.mat-mdc-button-disabled {
			background-color: var(--sl-disabled-mode-lines-main);
		}
	}

	// TODO: find better solution to avoid :not() material calendar button to be part of this change
	.mat-mdc-icon-button:not(.mat-calendar-period-button):not(.mat-calendar-previous-button):not(.mat-calendar-previous-button):not(.mat-calendar-next-button):not(.button-close) {
		&.mat-mdc-button-base {
			--mdc-icon-button-state-layer-size: 24px;
			--sl-icon-button-icon-size: 14px;

			&.icon-size-24 {
				--sl-icon-button-icon-size: 24px;
			}

			&.icon-size-20 {
				@include square(38px);
				--sl-icon-button-icon-size: 20px;
			}

			.mat-icon {
				@include square(var(--sl-icon-button-icon-size));
				font-size: var(--sl-icon-button-icon-size);
				margin: 0;
				position: absolute;
				transform: translate(-50%, -50%);
			}
		}

		.mat-mdc-button-ripple {
			border-radius: 50%;
		}

		.mat-mdc-button-touch-target {
			@include square(40px);
		}

		&.new-design {
			cursor: pointer;

			&.header-icon {
				@include color(var(--sl-primary-text-icon-main));
				@include square(48px);
			}

			&.primary-action {
				@include color(var(--sl-primary-text-icon-main));
				@include square(40px);
			}

			&.secondary-action {
				@include color(var(--sl-secondary-text-icon-main));
				@include square(32px);

				.mat-icon {
					font-size: 14px;
				}
			}

			&.small-action {
				@include color(var(--sl-primary-text-icon-main));
				@include square(24px);

				.mat-icon {
					font-size: inherit;
				}
			}

			&:disabled {
				@include color(var(--sl-secondary-text-icon-main));
				cursor: default;
			}
		}
	}

	.mat-mdc-button.new-design-dark {
		@include color(var(--sl-white-main));
		background-color: var(--sl-primary-brand-active);
		cursor: pointer;
		height: 40px;
		border-radius: 5px;
		padding: 0 space(2);
	}

	.p-button.p-button-link {
		@include color($sl-color-bg-primary-light);

		font-size: 12px;
		text-decoration: underline;
		padding: space();
		cursor: pointer;

		&:enabled:focus {
			box-shadow: none;
		}

		&::after {
			@include circle(8px);

			display: none;
			content: '';
			margin-left: space(0.5);
		}

		&--active::after {
			display: inline-block;
			background-color: $sl-color-success;
		}

		&--inactive::after {
			display: inline-block;
			background-color: var(--sl-secondary-text-icon-main);
		}

		&.inline {
			padding: 0;

			&::after {
				display: none;
			}
		}
	}

	.mat-mdc-fab {
		--mdc-typography-button-letter-spacing: normal;
	}
}
