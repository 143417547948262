/**********************/
/***** typography *****/
/**********************/
@use '../mixins.scss' as *;

@mixin sl-typography {
	@include color(var(--sl-primary-text-icon-main));
	font-family: Roboto, monospace;
	letter-spacing: normal;
}

@mixin sl-typography-stats {
	@include sl-typography;
	font-size: 40px;
	font-weight: 300;
	letter-spacing: -0.02em;
}

@mixin sl-typography-stats-small {
	@include sl-typography-stats;
	font-size: 24px;
	font-weight: 500;
}

@mixin sl-typography-title {
	@include sl-typography;
	@include color(var(--sl-primary-brand-active));
	font-size: 20px;
	font-weight: 400;
	letter-spacing: -0.02em;
}

@mixin sl-typography-title-large {
	@include sl-typography;
	font-size: 24px;
	font-weight: 500;
	letter-spacing: -0.02em;
}

@mixin sl-typography-body {
	@include sl-typography;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	font-style: normal;
}

@mixin sl-typography-body-bold {
	@include sl-typography-body;
	font-weight: 600;
}

@mixin sl-typography-body-xxl {
	@include sl-typography-body;
	font-size: 20px;
}

@mixin sl-typography-body-xxl-bold {
	@include sl-typography-body-xxl;
	font-weight: 600;
}

@mixin sl-typography-body-large {
	@include sl-typography-body;
	font-size: 16px;
}

@mixin sl-typography-body-large-bold {
	@include sl-typography-body-large;
	font-weight: 600;
}

@mixin sl-typography-body-medium {
	@include sl-typography-body;
	font-size: 12px;
}

@mixin sl-typography-body-medium-bold {
	@include sl-typography-body-medium;
	font-weight: 600;
}

@mixin sl-typography-body-small {
	@include sl-typography-body;
	font-size: 10px;
}

@mixin sl-typography-body-small-bold {
	@include sl-typography-body-small;
	font-weight: 600;
}

@mixin sl-typography-nav {
	@include sl-typography;
	font-size: 14px;
	font-weight: 500;
}

@mixin sl-typography-label {
	@include sl-typography;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.03em;
}

@mixin sl-typography-label-small {
	@include sl-typography-label;
	font-size: 10px;
}

@mixin sl-typography-button {
	@include sl-typography;
	@include color(var(--sl-white-main));
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.03em;
}

@mixin sl-typography-link-action {
	@include sl-typography;
	@include color(var(--sl-primary-brand-pressed));
	cursor: pointer;
	font-size: 12px;
	font-weight: 400;
	text-decoration: underline;

	&:hover {
		@include color(var(--sl-primary-brand-hover));
	}

	&.no-text-decoration {
		text-decoration: none;
	}
	
	&.default-cursor {
		cursor: default;
	}
}

@mixin sl-typography-link-action-large {
	@include sl-typography-link-action;
	font-size: 14px;
}

@mixin sl-typography-icon {
	cursor: pointer;
	position: relative;

	&:hover::after {
		content: '';
		border-radius: 50%;
		background-color: var(--sl-dark-background-main);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}
}

@mixin sl-typography-icon-header {
	@include sl-typography-icon;

	&:hover::after {
		@include square(48px);
	}
}

@mixin sl-typography-icon-action {
	@include sl-typography-icon;

	&:hover::after {
		@include square(40px);
	}
}

@mixin sl-typography-icon-secondary-action {
	@include sl-typography-icon;

	&:hover::after {
		@include square(32px);
	}
}

@mixin sl-typography-main-table-label {
	@include sl-typography;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.36px;
}

@mixin sl-typography-sub-table-title {
	@include sl-typography;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 1.3;
}

@mixin sl-font-weight-500 {
	font-weight: 500;
}

@mixin sl-line-height($line-height) {
	line-height: $line-height;
}

@mixin sl-hoverable-link {
	@include sl-typography;
	cursor: pointer;

	&:hover {
		@include color(var(--sl-primary-brand-hover));
	}
}