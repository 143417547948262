@use 'common' as *;

.sl-body {
	.snackbar-panel {
		@include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.3));
		background-color: var(--sl-primary-text-icon-main);
		background-size: contain;
		max-width: unset;
		padding: space() space(2);
		min-height: unset;
		max-height: unset;
		height: auto;
		width: 400px;

		&.error {
			background-color: $color-snackbar-error-background;
		}

		.mat-mdc-simple-snack-bar {
			font-size: 16px;

			> span {
				@include color(var(--sl-white-main));
				line-height: 1.4;
				white-space: pre-line;
			}
		}
	}
}
