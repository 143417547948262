@use 'common' as *;

.sl-body {
	.filter-panel-table.p-overlaypanel {
		overflow-y: auto;
		background-color: var(--sl-white-main);
		padding: 0;
		margin: 0;
		border-radius: 5px;

		.p-overlaypanel-content {
			margin: 0;
			padding: 0;
		}

		.header-wrapper {
			padding: space(2);
			border-bottom: 1px solid var(--sl-dark-background-400);
			position: relative;

			.pi-times {
				position: absolute;
			}
		}

		.active-filters-wrapper {
			background: var(--sl-dark-background-400);
			padding: space();
			border-radius: 5px;

			.pi-times {
				font-size: 12px;
			}
		}

		.sl-v1-accordion {
			padding: space();

			.p-listbox-list-wrapper {
				@include sl-fancy-scroll();
			}
		}

		.sl-v1-accordion-filters {
			padding: space();

			.p-listbox-list-wrapper {
				@include sl-fancy-scroll();
			}

			.p-accordion-tab {
				&.p-accordion-tab-active {
					.p-accordion-header-link {
						@include color(var(--sl-primary-text-icon-main));
						background: unset;
						background-color: var(--sl-dark-background-main);
						border-color: #c8c8c8;
					}
				}
			}
		}
	}
}
