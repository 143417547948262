.sl-v1-menu.p-menu {
	width: auto;
	border-radius: 6px;
	border: 0;
	box-shadow: 0px 2px 8px 0px rgba(70, 81, 90, 0.18);

	.p-menu-list {
		padding: 8px 0;
	}

	.p-menuitem {
		&:not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link {
			background: var(--sl-element-background-100);
		}

		&.p-highlight:not(.p-disabled)>.p-menuitem-content .p-menuitem-link {
			background: var(--sl-primary-brand-100);
		}

		>.p-menuitem-content {
			background: transparent !important;
			padding: space(0.5) space();

			.p-menuitem-link {
				pointer-events: auto;
				border-radius: 6px;
				white-space: nowrap;
				padding: space() space();

				* {
					white-space: nowrap;
				}

				.p-menuitem-text {
					color: unset;
				}

				&.with-hover {
					&:hover {
						background-color: var(--sl-dark-background-main) !important;
					}
				}
			}
		}
	}
}

.sl-v1-menu.p-dropdown {
	border-radius: 6px;
	background: var(--sl-element-background-100);
	border: none;
	padding: 0 space();

	.p-dropdown-label {
		font-size: 14px;
		font-weight: 500;
	}

	.p-dropdown-trigger {
		width: 20px;
	}
}

.sl-v1-menu.p-dropdown-panel {
	border-radius: 6px;
	background: var(--sl-white-main);
	box-shadow: 0px 2px 8px 0px rgba(70, 81, 90, 0.18);
	border: none;
	margin-top: 8px;

	.p-dropdown-items {
		padding: 0 space(1);
		max-height: space(20);
	}

	.p-dropdown-item {
		height: space(4);
		padding: 0;
		border-radius: 5px;
		margin: 0 space(1);
		display: flex;
		align-items: center;
		font-size: 14px;
		padding: space() space(2);
		margin: space() 0;

		&:hover {
			background-color: var(--sl-dark-background-main);
		}

		&.p-highlight {
			@include color(var(--sl-primary-text-icon-main));
			background-color: var(--sl-dark-background-main);
		}
	}
}