/**********************/
/***** typography *****/
/**********************/
@use './typography-mixins.scss' as *;

.sl-body {
	.sl-typography {
		@include sl-typography;
	}

	.sl-typography-stats {
		@include sl-typography-stats;
	}

	.sl-typography-stats-small {
		@include sl-typography-stats-small;
	}

	.sl-typography-title {
		@include sl-typography-title;
	}

	.sl-typography-title-large {
		@include sl-typography-title-large;
	}

	.sl-typography-body {
		@include sl-typography-body;
	}

	.sl-typography-body-bold {
		@include sl-typography-body-bold;
	}

	.sl-typography-body-xxl {
		@include sl-typography-body-xxl;
	}

	.sl-typography-body-xxl-bold {
		@include sl-typography-body-xxl-bold;
	}

	.sl-typography-body-large {
		@include sl-typography-body-large;
	}

	.sl-typography-body-large-bold {
		@include sl-typography-body-large-bold;
	}

	.sl-typography-body-medium {
		@include sl-typography-body-medium
	}

	.sl-typography-body-medium-bold {
		@include sl-typography-body-medium-bold
	}

	.sl-typography-body-small {
		@include sl-typography-body-small;
	}

	.sl-typography-body-small-bold {
		@include sl-typography-body-small-bold;
	}

	.sl-typography-body-italic {
		font-style: italic;
	}

	.sl-typography-nav {
		@include sl-typography-nav;
	}

	.sl-typography-label {
		@include sl-typography-label;
	}

	.sl-typography-label-small {
		@include sl-typography-label-small;
	}

	.sl-typography-button {
		@include sl-typography-button;
	}

	.sl-typography-link-action {
		@include sl-typography-link-action;
	}

	.sl-typography-link-action-large {
		@include sl-typography-link-action-large;
	}

	.sl-typography-icon {
		@include sl-typography-icon;
	}

	.sl-typography-icon-header {
		@include sl-typography-icon-header;
	}

	.sl-typography-icon-action {
		@include sl-typography-icon-action;
	}

	.sl-typography-icon-secondary-action {
		@include sl-typography-icon-secondary-action;
	}

	.sl-typography-main-table-label {
		@include sl-typography-main-table-label;
	}

	.sl-typography-sub-table-title {
		@include sl-typography-sub-table-title;
	}

	.sl-font-weight-500 {
		@include sl-font-weight-500;
	}

	.sl-line-height-normal {
		@include sl-line-height(normal);
	}

	.sl-line-height-middle {
		@include sl-line-height(1.4);
	}

	.sl-line-height-double {
		@include sl-line-height(2);
	}

	.sl-hoverable-link {
		@include sl-hoverable-link;
	}
	
	.unset-text-decoration {
		text-decoration: unset;
	}

	.sl-letter-spacing-md {
		letter-spacing: 0.36px;
	}

	.sl-letter-spacing-lg {
		letter-spacing: 0.48px;
	}

	.sl-letter-spacing-xl {
		letter-spacing: 1px;
	}
}
