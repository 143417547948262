$grab-area: 10px;
$grab-line: 4px;

html {
	--mdc-dialog-subhead-color: var(--sl-primary-text-icon-300);
	--mat-dialog-content-padding: 24px;
}

.sl-resizable_grab {
	width: $grab-area;
	content: '';
	height: 100%;
	opacity: 0.6;
	cursor: col-resize;

	&__inside {
		height: 100%;
		width: $grab-line;
		margin: auto;
		background:
			linear-gradient(
				0deg,
				var(--sl-background-main),
				var(--sl-primary-brand-focus),
				var(--sl-background-main)
			);
	}

	&:hover {
		opacity: 1;
	}
}

.sl-horizontal-break {
    border-bottom: 1px solid var(--sl-disabled-mode-lines-200);
	width: 100%;
}

.sl-vertical-break {
	border-right: 1px solid var(--sl-disabled-mode-lines-main);
	height: 100%;
	min-height: 26px;
}

.sl-no-pointer {
	pointer-events: none;
}

.hide-when-empty:empty {
	display: none;
}

.sl-round-border {
	border: 1px solid var(--sl-dark-background-main);
	border-radius: 8px;
}

.sl-border-dark {
	border: 1px solid var(--sl-disabled-mode-lines-500);
}