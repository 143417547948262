@use 'common' as *;

.sl-body {
	.mdc-text-field {
		padding-left: 0;

		.mdc-line-ripple--active {
			&::after {
				opacity: 0;
			}
		}
	}

	.mdc-text-field--filled {
		background-color: transparent;
		height: 48px;
	}

	.mdc-text-field--focused {
		&:not(.mdc-text-field--disabled) {
			.mdc-floating-label {
				@include color(var(--sl-secondary-text-icon-main));
			}
		}
	}
}
