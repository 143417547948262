
.p-toast.sl-v1 {
	.p-toast-message {
		border-radius: 8px;
		background-color: #0a508e;
		@include color(var(--sl-white-main));

		.p-toast-icon-close {
			@include color(var(--sl-white-main));
			height: 30px;
			width: 30px;
			padding: 10px;
			position: absolute;
			right: 8px;
			top: 8px;
		}

		.p-toast-message-content {
			padding: space(2);
			position: relative;

			.p-toast-summary {
				font-size: 20px;
				font-weight: 400;
			}
		}

		.p-toast-message-icon {
			@include color(var(--sl-white-main));

			font-size: 14px;

			.p-icon {
				margin-top: 4px;
				width: 20px;
				height: 16px;
			}
		}

		.p-toast-detail {
			background-color: var(--sl-primary-brand-hover);
			padding: space(2);
			border-radius: 8px;
			font-size: 14px;
			white-space: break-spaces;
		}
	}
}

