@use 'common' as *;

.sl-body {
	.p-chips.new-design {
		width: 100%;

		.p-chips-token {
			background: var(--sl-primary-brand-hover);

			.p-chips-token-label {
				font-size: 14px;
			}
		}

		.p-chips-multiple-container {
			width: 100%;
			padding: space() space(6) space() space(2);
			border: 1px solid var(--sl-disabled-mode-lines-main);
			box-sizing: border-box;
			border-radius: 5px;

			&:not(.p-disabled).p-focus {
				border-color: unset;
				box-shadow: unset;
			}

			.p-chips-input-token {
				input {
					font-family: Roboto;
					font-size: 14px;
					font-weight: 400;
					line-height: 1.4;

					&::placeholder {
						@include color(var(--sl-secondary-text-icon-main));
					}
				}
			}
		}

	}
}
