.p-dialog {
    border-radius: 9px;

    .p-dialog-content {
        padding: 20px;
        padding-top: 0;

        &:last-of-type {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }

    .p-dialog-header {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 20px;
        border: none;
        background: var(--sl-white-main);

        .p-dialog-title {
            color: var(--sl-primary-text-icon-300);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.48px;
        }
    }

    .p-datatable-footer {
        .p-paginator-rpp-options {
            display: none;
        }
    }
}


.p-dialog.sl-v1 {
	border: none;
	box-shadow: 0px 4px 6px 4px rgb(108 110 114 / 37%);

	.p-dialog-content:last-of-type {
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	.p-dialog-header {
		border-radius: 8px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		.p-dialog-title {
			font-size: 24px;
			font-weight: 500;
			line-height: 28px;
			letter-spacing: -0.02em;
			text-align: left;
		}
	}
}