.sl-v1-input-switch-mini.p-inputswitch {
	height: 16px;
	width: 28px;
	opacity: 1;

	&.p-inputswitch-checked {
		.p-inputswitch-slider {
			background: var(--sl-primary-brand-500);

			&::before {
				transform: translateX(12px);
			}
		}

		&.p-disabled {
			.p-inputswitch-slider {
				background: var(--sl-dark-background-400);
			}
		}
	}

	&.p-focus .p-inputswitch-slider {
		outline: 0 none;
		box-shadow: none;
	}

	&.p-disabled:not(.p-inputswitch-checked) {
		.p-inputswitch-slider {
			background: var(--sl-background-400);
		}
	}

	.p-inputswitch-slider {
		border-radius: 19px;
		background: var(--sl-secondary-text-icon-100);

		&::before {
			height: 12px;
			width: 12px;
			left: 2px;
			margin-top: -6px;
			background: var(--sl-white-main);
		}
	}
}
