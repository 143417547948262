@use 'common' as *;
@use '../table' as *;

@mixin table-header-cell {
	@include color(var(--sl-primary-text-icon-200));
	padding: 6px 12px;
	text-transform: none;
	height: unset;
	vertical-align: text-top;
	font-weight: 500;
	line-height: 1;
	font-size: 12px;
	letter-spacing: 0.36px;
}

@mixin table-footer {
	border: 1px solid var(--sl-dark-background-main);
	border-radius: 0 0 8px 8px;
	border-top: 0;
	display: flex;
	justify-content: flex-end;
}

.sl-v1-table-paginator {
	@include table-footer;

	.mat-mdc-paginator-container {
		min-height: 52px;

		.mat-mdc-paginator-range-label {
			min-width: 100px;
		}

		.mat-mdc-paginator-page-size-label {
			font-size: 14px;
		}

		.mdc-text-field {
			padding: 0 10px;
		}

		.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
			height: 32px;
			min-height: unset;
			display: flex;
			align-items: center;
		}
	}
}

.sl-v1-table-filters {
	border-radius: 8px 8px 0 0;
	background: var(--sl-white-main);
	border: 1px solid var(--sl-dark-background-main);
	border-bottom: 0;
	padding: space() space(3);
	gap: 16px;
}

.sl-v1-container {
	flex: 1;
	overflow: auto;
	flex-direction: column;
	display: flex;
	height: 100%;
	position: relative;

	sl-loading {
		position: absolute;
		z-index: 1;
		background: rgba(255, 255, 255, 0.5);
	}
}

.sl-v1-table-wrapper {
	@include sl-fancy-scroll();
	overflow-y: auto;
	width: 100%;
	height: 100%;
	border: 1px solid var(--sl-dark-background-main);
	background-color: white;
	position: relative;

	&.sl-v1-table-wrapper-rounded {
		border-radius: 8px;
	}

	.empty-table,
	.empty-table-search {
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		background: var(--sl-white-main);

		.empty-table-title {
			font-weight: 700 !important;
			font-size: 20px;
		}

		.empty-table-subtitle {
			font-size: 14px;
		}

		.reset-search {
			margin-top: space();
		}
	}

	table[mat-table].new-design {
		@include table-column(80px, 'createdBy');
		@include table-column(130px, 'created');
		@include table-column(232px, 'actions');

		&.sl-v1-table-level-2 {
			tr[mat-header-row] th[mat-header-cell] {
				text-transform: uppercase;
				font-size: 13px;
			}
		}

		.mat-sort-header-content {
			align-items: flex-start;
			text-align: left;
		}

		.mat-sort-header-arrow {

			.mat-sort-header-pointer-left, 
			.mat-sort-header-pointer-right {
				height: 2px;
				background-color: var(--sl-secondary-text-icon-300);
			}

			.mat-sort-header-stem {
				width: 2px;
				background-color: var(--sl-secondary-text-icon-300);
			}
		}

		tr[mat-header-row] {
			height: 40px;
			background-color: var(--sl-dark-background-200);

			th[mat-header-cell] {
				@include table-header-cell;

				.mat-sort-header-container {
					letter-spacing: inherit;
				}

				&:first-child {
					padding-left: space(3);
				}

				&:last-child {
					padding-right: space(3);
				}

				&.select {
					width: space(7);
				}
			}
		}

		tr[mat-row] {
			td[mat-cell] {
				padding: 6px 12px;

				&:first-child {
					padding-left: space(3);
				}

				&:last-child {
					padding-right: space(3);
				}

				&.mat-column-expandedDetail {
					padding-bottom: 0 !important;
					padding-top: 0 !important;
				}
			}
		}

		.mat-mdc-row {
			box-shadow: 0px -1px 0px var(--sl-dark-background-main);
			padding: space(3);
			height: 0;

			&:not(.expanded) {
				height: 56px;

				&:hover {
					background: var(--sl-dark-background-100);

					.action-field {
						.sl-icon-button-wrapper {
							visibility: visible;
						}

						.action-button {
							display: initial;
						}
					}
				}
			}

			&:hover {
				background: unset;
			}

			&.highlight {
				background: var(--sl-dark-background-100);
			}

			&.clickable {
				cursor: pointer;
			}

			.table-icon {
				@include square(16px);
				font-size: 16px;
			}
		}

		.action-field {
			.sl-icon-button-wrapper {
				visibility: hidden;
			}

			.action-button {
				@include circle(32px);
				@include color(var(--sl-primary-brand-active));
				font-size: 24px;
				display: none;
				cursor: pointer;

				&:active:hover:not(:disabled) {
					@include color(var(--sl-primary-brand-hover));
				}

				&:disabled {
					@include color(var(--sl-disabled-mode-lines-main));
					cursor: not-allowed;
				}

				&.red-button:not(:disabled) {
					@include color(var(--sl-alert-error-active));
				}

				&.red-button:active:hover:not(:disabled) {
					@include color(var(--sl-alert-error-100));
				}

				.mat-icon {
					@include circle(space(2.5));
					font-size: 20px;
				}
			}
		}
	}
}
