@use 'common' as *;

.sl-body {
	.customize-filter.p-overlaypanel {
		overflow-y: auto;
		background-color: var(--sl-white-main);
		padding: space(2);
		margin: 0;
		border-radius: 5px;

		.p-overlaypanel-content {
			margin: 0;
			padding: 0;
		}
	}
}
