@use 'common' as *;

p-tabview {
	&.line-view {
		height: 100%;
		.p-tabview {
			height: 100%;
		}
		&.hidden {
			display: none;
		}
		.p-tabview-nav-container {
			.p-tabview-nav-content {
				.p-tabview-nav {
					border-bottom: 1px solid var(--sl-disabled-mode-lines-main);

					li {
						margin-right: 0;
						&:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
							background-color: transparent;
							border-color: transparent;
						}
						&.p-highlight {
							.p-tabview-nav-link {
								@include color(var(--sl-primary-brand-pressed));
								border-bottom: 1px solid var(--sl-primary-brand-pressed);
							}
						}

						&.code-to-tests {
							.p-tabview-title {
								.mat-icon {
									@include color(var(--sl-primary-brand-pressed));
									@include square(14px);
									font-size: 14px;

									&.error {
										@include color(var(--sl-secondary-brand-yellow-main));
									}
								}
							}
						}

						.p-tabview-nav-link {
							@include sl-typography-nav;
							align-items: center;
							background-color: transparent;
							border: none;
							font-weight: 500;
							height: 42px;
							justify-content: center;
							margin-right: space(5);
							padding: 10px 16px;
							color: var(--sl-secondary-text-icon-300);

							&:not(.p-disabled):focus {
								box-shadow: none;
							}

							.p-tabview-title {
								@include sl-grid-columns;
								gap: space(0.5);
							}
						}
					}
				}
			}
		}
		.p-tabview-panels {
			border: none;
			height: calc(100% - 40px);
			.p-tabview-panel {
				height: 100%;
			}
		}

		&.p-0 {
			.p-tabview-panels {
				padding: 0;
			}
		}
	}

	&.block-view {
		.p-tabview {
			.p-tabview-nav-container {
				.p-tabview-nav-content {
					.p-tabview-nav:first {
						font-size: 70;
					}
					.p-tabview-nav:last {
						font-size: 100pt;
					}
					.p-tabview-nav {
						li[role='presentation'] {
							&:first-child {
								.p-tabview-nav-link {
									border-radius: 4px 0px 0px 4px;
									border-right: 0px;
								}
							}
						}
						li {
							width: 144px;
							flex-basis: 0%;
							flex-grow: 1;
							height: 40px;
							margin-right: 0;

							&.p-highlight {
								.p-tabview-nav-link {
									@include color(var(--sl-white-main));
									background-color: var(--sl-primary-brand-active);
								}
							}

							.p-tabview-nav-link {
								border-radius: 0;
							}

							.p-tabview-nav-link {
								@include color(var(--sl-secondary-text-icon-main));
								background-color: transparent;
								border-color: var(--sl-disabled-mode-lines-main);
								border-right-width: 0px;
								font-weight: 400;
								height: 100%;
								justify-content: center;

								&:not(.p-disabled):focus {
									box-shadow: none;
								}
							}
						}
					}
				}
			}
			.p-tabview-panels {
				display: none;
			}
		}
	}
}
