@use 'common' as *;

@mixin seriesSelector($seriesNames...) {
	@each $item in $seriesNames {
		&[seriesname='#{$item}'] {
			@content;
		}
	}
}

@mixin seriesLegendOverride($seriesName, $seriesColor) {
	@include seriesSelector($seriesName) {
		display: flex;
		position: relative;
		background: #{$seriesColor};
		padding: 2px space();
		border-radius: space();

		.apexcharts-legend-marker {
			display: none;
		}
	}
}

$legend-height: 16px;

.sl-body {
	.tia-tests-metrics-tooltip {
		padding: space(2);
	}
	.sl-qa-chart {
		padding: 0 space(2);
	}

	.apexcharts-series {
		transition: 300ms ease opacity;

		&.legend-mouseover-inactive {
			opacity: 0;
		}
	}

	.apexcharts-bar-series {
		.legend-mouseover-inactive {
			opacity: 0.4;
		}
	}

	.apexcharts-canvas .apexcharts-legend {
		overflow: visible;

		.apexcharts-legend-series {
			display: none;
			height: $legend-height;

			@include seriesSelector('defects', 'methods') {
				display: block;
			}

			.apexcharts-legend-marker {
				margin-right: 0;
				min-width: 100%;
				min-height: 100%;
			}

			.apexcharts-legend-text {
				top: -#{$legend-height};
				line-height: 10px;
				display: block;
				pointer-events: none;
				padding: 0 space();
			}
		}
	}

	.apexcharts-xaxis-label,
	.apexcharts-yaxis-label {
		pointer-events: none;
	}

	.apexcharts-xaxis line {
		display: none;
	}

	.apexcharts-tooltip-title {
		display: none;
	}

	.apexcharts-gridlines-horizontal {
		transform: translateY(1px);
	}

	.apexcharts-gridlines-vertical {
		transform: translateX(-1px);
	}

	.apexcharts-xcrosshairs.apexcharts-active {
		transform: translateX(1px);
	}

	.apexcharts-grid-borders .apexcharts-gridline:last-child {
		stroke: var(--sl-disabled-mode-lines-main);
		stroke-dasharray: none;
	}

	.apexcharts-datalabels {
		transform: translateY(-5px);
	}

	.apexcharts-marker {
		opacity: 0;
	}

	.hide-markers {
		.apexcharts-marker {
			display: none;
		}
	}

	.apexcharts-svg {
		overflow: visible;
	}

	.apexcharts-tooltip {
		@include color(var(--sl-primary-text-icon-main));

		top: 10px !important;
		border: none;

		&.apexcharts-theme-light {
			border: none;
			box-shadow: 0 2px 10px var(--sl-disabled-mode-lines-main);
		}
	}

	.apexcharts-text {
		font-family: $font-family;
	}

	.hideLines .apexcharts-line {
		display: none;
	}

	.aggregatedLoading .apexcharts-series[seriesName='aggregated'] {
		animation: pulsateOpacity 2s ease-in-out infinite;
	}

	.apexcharts-series {
		&[seriesName='aggregated'] {
			filter: drop-shadow(0 4px 5px var(--sl-disabled-mode-lines-main));
		}

		&[seriesName='entire'],
		&[seriesName='aggregated'] {
			.apexcharts-marker {
				stroke-width: 0;
				opacity: 1;
				r: 2.5;
			}

			.apexcharts-marker[r='7'] {
				opacity: 1;
				fill: white;
				stroke-opacity: 1;
				r: 7;
				stroke-width: 6px;
				position: relative;
			}
		}
	}

	.apexcharts-series[seriesName='empty'] {
		.apexcharts-line {
			stroke-linecap: butt;
		}
	}

	.apexcharts-series.hidden {
		display: none;
	}

	.trend-report-series-tooltip-template,
	.tia-build-stats-tooltip-template {
		@include color(var(--sl-primary-text-icon-main));

		min-width: 370px;
		width: 370px;
		background: var(--sl-white-main);
		border: 0.5px solid var(--sl-disabled-mode-lines-main);
		box-sizing: border-box;
		border-radius: 5px;
		padding: space();

		.info {
			order: 1;
			padding: space();
		}

		.date,
		.build {
			font-weight: 500;
			margin-bottom: space();
			text-transform: capitalize;
			line-height: 1.15;
		}

		.build {
			margin-bottom: space(0.5);
		}

		.date-small {
			@include color(var(--sl-secondary-text-icon-main));

			margin-bottom: space();
			order: 2;
			font-size: 10px;
			line-height: 1.1;
		}

		.tooltip-item {
			position: relative;
			order: 6;
			width: 100%;
			line-height: 1;
			padding: space();
			padding-left: space(3);

			.insight-icon {
				position: absolute;
				left: 2px;
			}

			.tooltip-item-label {
				line-height: 1.1;
			}

			&.coverage-gate {
				order: 3;

				.percent-label {
					font-size: 14px;
				}
			}

			&.entire {
				order: 4;
				margin-bottom: space();

				.percent-label {
					@include color($sl-color-success);
					font-size: 24px;
				}
			}

			&.aggregated {
				order: 5;
				background-color: var(--sl-background-main);
				margin-bottom: space();

				.percent-label {
					@include color(var(--sl-primary-brand-active));
					font-size: 24px;
				}
			}

			&.coverage-gate,
			&.aggregated,
			&.entire {
				padding-left: space();

				.tooltip-item-label {
					font-size: 14px;
					line-height: 16px;
				}
			}

			.color-box {
				@include square(14px);

				margin-right: space();
				border-radius: 2px;
			}

			.percent-label,
			.tooltip-item-label {
				@include color(var(--sl-primary-text-icon-main));

				text-align: start;
			}

			.percent-label {
				font-size: 12px;
				margin-left: auto;
				margin-bottom: auto;
				order: 4;
			}

			.test-stage-tia {
				margin-left: space(0.5);
			}

			.total-methods {
				margin: 0;
			}

			.total-production-defects {
				white-space: pre;
			}
		}

		.insight-tooltip {
			margin-left: space(-2);
			background-color: var(--sl-secondary-brand-yellow-main);
			padding: space() space(2);
			height: 30px;
			width: calc(100% + 26px);

			img {
				margin-right: space();
			}
		}
	}
}
