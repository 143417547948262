@use 'common' as *;

.sl-body {
	.mat-pseudo-checkbox-checked {
		background-color: var(--sl-black-main);
	}

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-frame {
		border-radius: 0 !important;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-checked,
	.mat-checkbox-indeterminate {
		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-background {
			background-color: var(--sl-black-main);
			border-radius: 0 !important;
		}
	}

	.mat-mdc-checkbox {
		/*
		** TODO: consider replacing literal colors with SL colors when we declare SL variables as proper CSS variables.
		** Example: --mdc-checkbox-selected-checkmark-color: --sl-color-checkbox-selected;
		*/
		--mdc-checkbox-selected-checkmark-color: var(--sl-white-main);
		--mdc-checkbox-selected-icon-color: var(--sl-black-main);
		--mdc-checkbox-selected-focus-icon-color: var(--sl-black-main);
		--mdc-checkbox-selected-hover-icon-color: var(--sl-black-main);
		--mdc-checkbox-selected-pressed-icon-color: var(--sl-black-main);
		--mdc-checkbox-state-layer-size: 0;
		--mdc-checkbox-unselected-focus-state-layer-opacity: 0;

		&.mat-mdc-checkbox-disabled {
			.mdc-checkbox {
				.mdc-checkbox__background {
					--mdc-checkbox-disabled-selected-icon-color: transparent;

					.mdc-checkbox__checkmark {
						@include color(inherit);
					}
				}
			}
		}

		.mdc-checkbox {
			.mdc-checkbox__background {
				@include square(16px);
			}
		}

		.mdc-form-field {
			--mdc-typography-body2-letter-spacing: normal;
		}
	}
}
