/* CSS-GRID */

@mixin sl-grid {
	display: grid;
}

@mixin sl-grid-columns {
	@include sl-grid;
	grid-auto-flow: column;
}

@mixin sl-grid-rows {
	@include sl-grid;
	grid-auto-flow: row;
}

@mixin sl-grid-auto-flow-column {
	grid-auto-flow: column;
}

@mixin sl-grid-auto-flow-row {
	grid-auto-flow: row;
}

@mixin sl-align-items-vertical-start {
	align-items: start;
}

@mixin sl-align-items-vertical-center {
	align-items: center;
}

@mixin center-by-grid {
	@include sl-grid;
	height: 100%;
	place-items: center;
}
