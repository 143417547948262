@use 'common' as *;

.sl-body {
	.mat-mdc-radio-button {
		--mdc-radio-selected-icon-color: var(--sl-primary-text-icon-main);
		--mdc-radio-selected-hover-icon-color: var(--sl-primary-text-icon-main);

		.mdc-form-field {
			--mdc-typography-body2-font-size: 12px;
			--mat-checkbox-label-text-size: 12px;
		}

		.mdc-radio {
			--mdc-radio-state-layer-size: 14px;
			height: 14px;
		}

		.mdc-radio__background {
			@include square(14px);
		}

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mdc-radio__outer-circle {
			border-width: 1px;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		&.mat-radio-checked {
			/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
			.mat-radio-outer-circle {
				border-color: var(--sl-primary-text-icon-main);
			}
			/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
			.mat-radio-inner-circle {
				transform: scale(0.7);
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mdc-radio__inner-circle {
			background-color: var(--sl-primary-text-icon-main);
			border-width: 0;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-label-content {
			padding-left: space(2);
		}
	}
}
