@use 'common' as *;

.sl-body {
	.p-calendar.new-design {
		width: 100%;
		height: 40px;

		.p-inputtext {
			@include sl-typography-body;
			padding: 0 space(2);
		}

		&.inline {
			height: auto;
		}

		&.no-border {
			.p-datepicker {
				border: 0;
			}
		}

		.p-button {
			background: var(--sl-primary-brand-active);
			border: 1px solid var(--sl-primary-brand-active);

			&:hover {
				background: var(--sl-primary-brand-hover);
				border: 1px solid var(--sl-primary-brand-hover);
			}
		}
	}

	.tga-calendar-panel-style {
		.timerange-custom {
			padding: space(2);
			border-top: 1px solid #d8dae2;

			.p-timepicker {
				display: flex;
			}

			.p-datepicker {
				border: none;
				padding: 0;
			}

			&.timerange-custom-left {
				border-right: 1px solid #d8dae2;
			}
		}

		.footer-buttons {
			border-top: 1px solid #d8dae2;
			padding-top: space(3);
		}

		.p-timepicker {
			display: none;
		}
	}
}