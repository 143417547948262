@use 'common' as *;

.p-tooltip {
	border-radius: 5px;
	border: 1px solid var(--sl-dark-background-400);
	box-shadow: 0px 4px 8px -2px rgba(57, 60, 67, 0.16);
	max-width: 100vw;
	padding: 0;

	.p-tooltip-text {
		@include color(var(--sl-primary-text-icon-300));
		background: var(--sl-white-main);
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: space();
		box-shadow: none;
	}

	.p-tooltip-arrow {
		display: none;
	}

	&.p-tooltip-bottom {
		margin-top: space();
	}

	&.p-tooltip-right  {
		margin-left: 5px;

		.p-tooltip-arrow {
			left: -1px;
		}
	}

	&.p-tooltip-left  {
		margin-right: 5px;

		.p-tooltip-arrow {
			right: -1px;
		}
	}

	&.p-tooltip-small {
		max-width: 300px;
	}

	&.p-tooltip-no-wrap {
		.p-tooltip-text {
			white-space: nowrap;
		}
	}

	&.p-tooltip-pre-line {
		.p-tooltip-text {
			white-space: pre-line;
			word-break: keep-all;
		}
	}
}
