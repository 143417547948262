@use 'common' as *;

.sl-body {
	.mat-mdc-paginator {
		.mat-mdc-paginator-outer-container {
			.mat-mdc-paginator-container {
				.mat-mdc-paginator-range-actions {
					.mat-mdc-paginator-range-label {
						@include sl-typography-body;
						margin: 0 space(2) 0 0;
					}

					.mat-mdc-icon-button {
						@include sl-typography-body;
						@include square(space(4));
						border-radius: 5px;
						line-height: space(4);
						margin: 0 space();
						padding: space(0.5) 0;

						&:hover {
							/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
							&:not(.mat-button-disabled) {
								background-color: var(--sl-dark-background-main);
							}
						}

						/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
						&.mat-button-disabled {
							@include color(var(--sl-secondary-text-icon-main));
						}

						/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
						.mat-button-wrapper {
							.mat-mdc-paginator-icon {
								@include square(space(3));
							}
						}
					}
				}
			}
		}
	}
}
