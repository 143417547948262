@use 'common' as *;

.sl-body {
	.p-component {
		font-family: Roboto, monospace;

		*:not(.material-icons):not(sl-icon) {
			font-family: Roboto, monospace;
		}

		.pi {
			font-family: primeicons;

			&::before, &::after {
				font-family: primeicons;
			}
		}
	}

	.p-inputtext {		
		&:enabled {
			&:focus,
			&:hover {
				border-color: var(--sl-disabled-mode-lines-main);
				box-shadow: none;
			}
		}
	}

	.p-footer {
		height: space(4);
		background-color: var(--sl-element-background-main);
		border-top: 1px solid var(--sl-disabled-mode-lines-main);
		padding: 0 space(2);

		.load-next {
			cursor: pointer;
			flex: 1;
		}

		.mat-mdc-progress-spinner {
			margin-right: space();
		}

		.hidden {
			display: none;
		}
	}
}
