@use 'common' as *;

.sl-body {
	.p-checkbox {
		&:not(.p-checkbox-disabled) {
			.p-checkbox-box {
				&.p-highlight {
					&.p-focus,
					&:hover {
						background-color: var(--sl-primary-text-icon-main);
						border: 1px solid var(--sl-primary-text-icon-main);
					}
				}

				&.p-focus {
					border: 1px solid var(--sl-primary-text-icon-main);
					box-shadow: none;
				}
			}
		}

		.p-checkbox-box {
			&.p-highlight {
				background-color: var(--sl-primary-text-icon-main);
				border: 1px solid var(--sl-primary-text-icon-main);

				&.p-disabled {
					background: var(--sl-primary-brand-400) !important;
					border-color: var(--sl-primary-brand-400) !important;
					opacity: 1;
				}
			}

			&.p-focus {
				border: 1px solid var(--sl-primary-text-icon-main);
				box-shadow: none;
			}

			.p-icon {
				@include color(var(--sl-white-main));
			}

			&.p-disabled:not(.p-highlight) {
				background: var(--sl-disabled-mode-lines-100) !important;
				border-color: var(--sl-element-background-300) !important;
				opacity: 1;
			}
		}

		&.new-design {
			@include sl-typography-body;

			&:not(.p-checkbox-disabled) {
				.p-checkbox-box {
					&.p-highlight {
						&:hover {
							background-color: unset;
							border: 1px solid var(--sl-primary-text-icon-main);
						}
					}

					&.p-focus {
						background-color: unset;
						border: 1px solid var(--sl-primary-text-icon-main);
						box-shadow: none;
					}
				}
			}

			.p-checkbox-box {
				background-color: var(--sl-element-background-main);
				border: 1px solid var(--sl-primary-text-icon-main);

				&:hover {
					background-color: var(--sl-element-background-main);
					border: 1px solid var(--sl-primary-text-icon-main);
				}

				&.p-focus {
					box-shadow: unset;
					outline: 0 none;
					outline-offset: 0;
				}
			}
		}
	}
}
