@use 'common' as *;

.sl-body {
	.p-autocomplete-panel.new-design {
		@include sl-fancy-scroll;
		@include sl-typography-body;
		background-color: var(--sl-element-background-main);
		border-radius: 0 0 space(0.5) space(0.5);
		max-width: $sl-dropdown-width-max;
		margin-top: -1px;
		box-shadow: $sl-box-shadow;
		box-sizing: content-box;
		z-index: 1101 !important; // on top of modals

		.p-autocomplete-items {
			padding: space() 0;

			.p-autocomplete-item {
				height: space(4);
				padding: 0;
				border-radius: $sl-border-radius;
				margin: 0 space(1);
				display: flex;
				align-items: center;

				&:hover {
					background-color: var(--sl-dark-background-main);
				}

				&.p-highlight {
					@include color(var(--sl-primary-text-icon-main));
					background-color: var(--sl-dark-background-main);
				}

				.autocomplete-item {
					padding: space();
					height: 100%;
					flex: 1;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					display: inline-block;
					line-height: space(2);

					&.prevent-interaction {
						cursor: default;
					}
				}
			}
		}
	}
}
