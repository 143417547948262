@use 'common' as *;

.sl-body {
	.p-breadcrumb {
		@include sl-typography-title;
		background-color: transparent;
		border: none;
		padding: 0;

		.p-element {
			.p-menuitem-link {
				.p-menuitem-text {
					@include color(var(--sl-primary-text-icon-main));
					font-size: 24px;
				}
			}
		}

		.p-breadcrumb-chevron {
			&.pi-chevron-right {
				@include sl-typography-body-small-bold;
				@include square(12px);
				text-align: center;
			}
		}
	}
}
