
.p-datatable.sl-v1 {
	.p-datatable-thead>tr>th {
		@include color(var(--sl-secondary-text-icon-300));
		font-size: 12px;
		background-color: var(--sl-dark-background-400);
		border-radius: 0;
		border: none;
		border-bottom: 1px solid var(--sl-dark-background-main);
		font-weight: 500;

		&:first-child {
			border-top-left-radius: 8px;
		}

		&:last-child {
			border-top-right-radius: 8px;
		}
	}

	.p-datatable-tbody>tr>td {
		font-size: 14px;
		padding: space(2);
		line-height: 1;
		border: none;

		border-bottom: 1px solid var(--sl-dark-background-main);

		&:first-child {
			border-left: 1px solid var(--sl-dark-background-main);
		}

		&:last-child {
			border-right: 1px solid var(--sl-dark-background-main);
		}
	}

	.p-sortable-column {

		&:hover {
			background: #bbccdd;
		}

		.p-element {
			display: none;
		}

		&.p-highlight {

			&:hover {
				background: #bbccdd;
			}

			.p-element {
				display: inline;
			}

			.p-sortable-column-icon {
				@include color(var(--sl-secondary-text-icon-300));
				max-height: 11px;
			}
		}
	}

}