@use 'common' as *;

.sl-body {
	.p-radiobutton.new-design {
		.p-radiobutton-box {
			&.p-highlight {
				border-color: var(--sl-primary-text-icon-main);
				background: var(--sl-primary-text-icon-main);

				&:not(.p-disabled) {
					&.p-focus {
						outline: none;
						box-shadow: none;
					}

					&:hover {
						border-color: var(--sl-black-main);
						background: var(--sl-black-main);
					}
				}
			}

			.p-radiobutton-icon {
				@include circle(8px);
			}
		}
	}

	.p-radiobutton-label.new-design {
		@include sl-typography-body;
	}
}
