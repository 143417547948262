@use 'common' as *;

.sl-v1-empty-state-icon {
    width: 310px;
    height: 180px;
    background: url('/assets/images/tia-empty-state.svg') no-repeat center center;
}

.sl-v1-empty-state-title {
    @include color(var(--sl-primary-brand-active));
    text-align: center;
    font-family: Roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sl-v1-empty-state-subtitle {
    @include color(var(--sl-primary-brand-active));
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}