.p-radiobutton {
	width: 16px;
	height: 15px;

	&+.p-radiobutton-label {
		@include color(var(--sl-primary-text-icon-300));
		line-height: 17px;
		font-size: 14px;
		font-weight: 400;
		margin-left: 8px;
		cursor: pointer;
		opacity: 1;
	}

	.p-radiobutton-box {
		height: 16px;
		width: 16px;

		&.p-highlight {
			border: 1px solid var(--sl-primary-brand-active);
			background: var(--sl-primary-brand-active);

			&:not(.p-disabled):hover {
				border: 1px solid var(--sl-primary-brand-active);
				background: var(--sl-primary-brand-active);
			}
		}

		&.p-disabled {
			&:not(.p-highlight) {
				border: 1px solid var(--sl-element-background-300);
				background: var(--sl-disabled-mode-lines-100);
			}
			opacity: 1;
		}
	}

	.p-radiobutton-icon {
		width: 6px;
		height: 6px;
		background-color: var(--sl-white-main);
	}

	&.p-radiobutton-checked .p-radiobutton-box {
		// border: 1px solid var(--sl-primary-brand-active);
		// background: var(--sl-primary-brand-active);
	}

	&.p-radiobutton-disabled {
		pointer-events: none;
	}

}