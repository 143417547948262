// All measures are expressed in rems instead of pixels

$prefix: 'sl';

$space-values: (
	'auto': 'auto',
	'0': 0,
	'xxs': '2px',
	'xs': '4px',
	'sm': '8px',
	'md': '12px',
	'lg': '16px',
	'xl': '24px',
	'xxl': '32px',
	'xxxl': '48px',
);
$sides: (
	'': 'all',
	't': 'top',
	'r': 'right',
	'b': 'bottom',
	'l': 'left',
);

@each $space-key, $space-value in $space-values {
	@each $side-key, $side-value in $sides {
		$property: if($side-key == '', '', -#{$side-value});

		.sl-body .m#{$side-key}-#{$space-key} {
			margin#{$property}: #{$space-value};
		}

		.sl-body .p#{$side-key}-#{$space-key} {
			padding#{$property}: #{$space-value};
		}
	}
}
