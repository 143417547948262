@use 'common' as *;

$table-cell-padding: space(1.5);
$table-cell-padding-edge: #{$table-cell-padding * 2};

.sl-body {
	table[mat-table].new-design {
		border-collapse: collapse;
		table-layout: fixed;
		width: 100%;

		&.row-separators {
			tr[mat-row] {
				td[mat-cell] {
					border-bottom: 0.5px solid var(--sl-disabled-mode-lines-main);
				}
			}
		}

		tr[mat-header-row] {

			th[mat-header-cell] {
				@include sl-typography-label;
				border-bottom: 0.5px solid var(--sl-disabled-mode-lines-main);

				&.center {
					text-align: center;
	
					.mat-sort-header-container {
						justify-content: center;
					}
				}
			}

			
		}

		tr[mat-header-row], tr[mat-row] {
			height: space(6);

			@include screen-md {
				height: space(7);
			}

			@include screen-sm {
				height: space(6);
			}
		}

		tr[mat-header-row] th[mat-header-cell], tr[mat-row] td[mat-cell] {

			padding: 0 $table-cell-padding;

			&:first-of-type {
				padding-left: $table-cell-padding-edge;
			}

			&:last-of-type {
				padding-right: $table-cell-padding-edge;
			}

			@include screen-sm {
				padding: 0 space(1);
			}
		}

		tr[mat-row] {
			cursor: default;

			td[mat-cell] {
				@include sl-typography-body;
				border-bottom: none;
			}
		}
	}
}
